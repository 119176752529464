// Elements
//
// Things to review before you write code here:
// - Be sure to load after "foundation" import.
// - Also, before adding styles be sure to modify variables in the
//   "scss/_variables.scss" file. You may not need to write any code.

// Headings
//------------------------------------------
// h1, h2, h3,
// h4, h5, h6 {}

// Anchors
//-----------------------------------------------------------------------------
 a {
   @extend .primary-color_color; 
 }

a:focus {
  @extend .primary-color_color; 
}

 a.active,
 a:active,
 a:hover {
   @extend .primary-color_color; 
 }

// Form Input
//-----------------------------------------------------------------------------
// input[type="text"],
// input[type="password"],
// input[type="date"],
// input[type="datetime"],
// input[type="datetime-local"],
// input[type="month"],
// input[type="week"],
// input[type="email"],
// input[type="number"],
// input[type="search"],
// input[type="tel"],
// input[type="time"],
// input[type="url"],
// textarea {
// }


// input[type="text"],
// input[type="password"],
// input[type="date"],
// input[type="datetime"],
// input[type="datetime-local"],
// input[type="month"],
// input[type="week"],
// input[type="email"],
// input[type="number"],
// input[type="search"],
// input[type="tel"],
// input[type="time"],
// input[type="url"],
// textarea {

// }

// select {}

blockquote {
  margin: 40px 0 40px 20px;
  p {
    .wd-post__content &, & {
      font-size: 1.3rem;
    }
  }
}

mark {
  background-color: rgba(255,255,0,0.2);
  color: #000;
}



table thead th,
table thead td,
table tfoot th,
table tfoot td {
  padding: .5rem .625rem .25rem;
}
