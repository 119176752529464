.wd_custom_box {
  padding-top: 22px;
  padding-right: 35px;
  padding-bottom: 22px;
  padding-left: 35px;
  border-radius: 15px;
}

.wd_custom_form {
  margin-top: 200px;
  position: relative;
}

.woocommerce {
  .product_title {
    font-size: 20px;
    font-weight: 800;
    color: #2E2E2E;
  }
  .flex-control-thumbs li {
    padding: 0 3px;
    width: 11%;
    margin-top: 20px;
  }
  .flex-control-thumbs {
    margin: 10px -5px 0;
    display: flex;
    justify-content: center;
    text-align: center;
    list-style: none;
  }
  .posted_in a {
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    margin: 2px 6px;
    font-size: 12px;
    letter-spacing: 1px;
  }
  form {
    .cart {
      margin-bottom: 2em;
    }
    button.single_add_to_cart_button {
      .right_btn {
        top: -51px;
        right: -109px;
      }
    }
  }
  .woocommerce-tabs {
    ul.tabs {
      margin-bottom: 0;
      margin-top: 30px;
      list-style-type: none;
      border: unset;
      li a {
        background: unset;
        &:after {
          /* background: #cccccc;
           content: "";
           display: inline-block;
           height: 25px;
           margin-left: 15px;
           position: absolute;
           top: 18px;
           width: 1px;*/
        }
      }
    }
    .tabs-content {
      border: unset;
    }
  }
  /* div.product {
     .woocommerce-tabs {
       ul.tabs {
         li.active {
           padding: 10px 30px 0 10px !important;
           margin: 0;
         }
         li a {
           display: inline-block;
           padding: .5em 0;
           font-weight: 700;
           color: #515151;
           text-decoration: none;
           background: unset ;
           &::after {
             background: #cccccc;
             content: "";
             display: inline-block;
             height: 25px;
             margin-left: 15px;
             position: absolute;
             top: 18px;
             width: 1px;
           }
         }
       }
     }
 }*/

}

.related {
  h2 {
    font-size: 1.375rem;
  }
}
