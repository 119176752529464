.primary-color_bg {
  background-image: $primary-bg;
}

.primary-color_color {
  color: $primary-color;
}

.primary-color_border {
  border-color: $primary-color;
}


.small-shadow {
  box-shadow: 0 10px 50px rgba(4, 75, 194, 0.1);
  border: 1px solid rgba(88, 136, 247, 0.1);
}
.small-shadow--noborder {
  box-shadow: 0 10px 50px rgba(4, 75, 194, 0.1);
  border: none;
}
.small-shadow:hover {
  box-shadow: 0 20px 60px rgba(4,75,194,0.2);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.small-shadow--with-hover {
  @extend .small-shadow:hover;
  transform: translate3d(0, -10px, 0) !important;
}


.medium-shadow {
  box-shadow: 0 12px 29px rgba(64, 124, 191,0.2);
}
.large-shadow {
  box-shadow: 0 3px 30px rgba(64, 124, 191,0.2);
}
.vc-row--bottom-shadow {
  box-shadow: 0 0 56px rgba(0, 0, 0, 0.07);
}



