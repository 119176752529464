.wd-latest-posts-widget {
  ul {
    margin: 0;
  }
  &__post {
    display: flex;
    padding: 14px 0;
  }
  &__image {
    flex: 0 0 120px;
    margin-right: 20px;
    img {
      border-radius: 10px;
      border: 1px solid rgba(0, 82, 255, 0.15);
    }
  }
  &__title {
    a {
      color: $headings-color;
    }
  }
  &__meta {
    color: $secondary-text-color;
  }
}




/*-----------search -----------------*/
.woocommerce-product-search {
  position: relative;
}
.searchform #s,
.woocommerce-product-search .search-field {
  padding: 30px 50px 27px 25px;
  border-radius: 30px 30px 0 30px;
}
.searchform,
.woocommerce-product-search {
  button[type='submit'] {
    border: 0 none;
    box-shadow: none;
    position: absolute;
    right: 18px;
    top: 15px;
    transition: all 0.3s ease-in-out 0s;
    background: transparent url("../images/icon/search-icon.png") no-repeat;
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-size: cover;
    color: transparent; // to hide value attribute
    &:after {
      display: none;
    }
  }
}
.first-footer #searchsubmit i {
  color: #fff;
}


/*--------------  Popular tags ----------------------------------*/
.tag-cloud-link {
  display: inline-block;
  border-radius: 28px;
  margin-right: 5px;
  margin-bottom: 15px;
  padding: 10px 25px 6px;
  background: $primary-color;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 12px !important;
  &:hover {
    background: $accent-color;
  }
}

/*--------------  Services Menu ----------------------------------*/
.menu-services-menu-container {
  ul li {
    padding: 0px 0px 0px 26px;
    a {
      padding: 0 5px;
    }
  }
}