/* Preloader */
body {
  overflow-x: hidden;
}
h1 {
  font-size: 4rem;
}
.alert-box {
  p {
    margin: 0;
  }
}
br {
  display: block;
  margin: 5px 0;
}
br {
  line-height: 40px;
}
thead {
  color: #fff;
}
#preloader {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:#fff; /* change if the mask should have another color then white */
  z-index:99; /* makes sure it stays on top */
}

#status {
  width:200px;
  height:200px;
  position:absolute;
  left:50%; /* centers the loading animation horizontally one the screen */
  top:50%; /* centers the loading animation vertically one the screen */
  background-image:url(../images/big-preloader.gif); /* path to your loading animation */
  background-repeat:no-repeat;
  background-position:center;
  margin:-100px 0 0 -100px; /* is width and height divided by two */
}

select {
  color: #000000;
}

@media only screen and (max-width: 768px) {
  html body.admin-menu {
    margin-top: 80px !important;
  }
}

@include breakpoint(large up) {
  .mi-row,
  .mi-row-right,
  .min-row-child > div {
    max-width: $grid-row-width/2;
    float: right;
    padding: 0 30px;
  }
  .mi-row-right {
    float: left !important;
  }
}

.textwidget {
    font-family: inherit;
    font-size: 1em;
    margin-bottom: 1.42857em;
}

.wp-caption {
  max-width: 100%;
}
.wp-caption-text {
  -webkit-box-sizing: border-box;
  -moz-box-sizing:    border-box;
  box-sizing:         border-box;
  font-size: 12px;
  font-style: italic;
  line-height: 1.5;
  margin: 9px 0;
}
.gallery-caption {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-box-sizing: border-box;
  -moz-box-sizing:    border-box;
  box-sizing:         border-box;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  max-height: 50%;
  opacity: 0;
  padding: 6px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  width: 100%;
}
.bypostauthor > article .fn:before,
.comment-edit-link:before,
.comment-reply-link:before,
.comment-reply-login:before,
.comment-reply-title small a:before,
.contributor-posts-link:before,
.menu-toggle:before,
.search-toggle:before,
.slider-direction-nav a:before,
.widget_twentyfourteen_ephemera .widget-title:before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal 16px/1 Genericons;
  text-decoration: inherit;
  vertical-align: text-bottom;
}
.alignright {
  float: right;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.alignleft {
  float: left;
}
h2.heading {
  color:inherit;
}
.page-links {
  text-align: center;
  a {
    padding: 10px 20px;
    border-radius: 50%;
    &:hover {
      color: #fff;
    }
  }
}
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
  clear: both;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
    color: #000;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

.clearfix {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
.flex {
  display: flex;
  align-items: center;
  @include breakpoint(small only) {
    display: block;
  }
}