//--------------- style 1
.team-list-style1 {
  margin: 0;
  li {
    list-style: none;
  }
  .team-member {
    &__picture {
      padding: 0 15px;
      margin: 0 15px;
      img {
        border-radius: 50%;
        transition: all .3s cubic-bezier(.02, .54, .58, 1);
      }
    }
    &__info {
      text-align: center;
    }
    &__name {
      margin-top: 30px;
      margin-bottom: 5px;
      span {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          bottom: 3px;
          left: 0;
          background: $primary-color;
          visibility: hidden;
          border-radius: 5px;
          transform: scaleX(0);
          transition: .10s linear;
        }
      }
    }
    &:hover {
      .team-member__picture img {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        transform: translate3d(0, -8px, 0);
      }
      h4 span:before, h3 span:before {
        visibility: visible;
        transform: scaleX(1);
      }

    }
  }
}

//***********************    Style 2   **********************/
.team-list-style2 {
  margin: 0;
  > li {
    list-style: none;
    padding: 0 20px;
    @include breakpoint(small only) {
      padding: 0 5px;
    }
  }
  .team-member {
    @extend .small-shadow--noborder;
    border-radius: 30px 30px 0 30px;
    transition: all 0.35s cubic-bezier(.45, .05, .55, .95);
    overflow: hidden;
    position: relative;
    &:hover {
      @extend .small-shadow:hover;
      transform: translate3d(0, -20px, 0);
    }
    &__picture {
      padding: 0;
      img {
        border-radius: 5px 5px 0 0;
      }
    }
    &__info {
      text-align: center;
      padding: 30px 30px 6px 30px;
      @include breakpoint(small only) {
        padding: 30px 15px 6px 15px;
      }
    }
    &__name {
      margin: 0;
    }
    &__socialmedia {
      display: inline;
      position: absolute;
      top: 10px;
      right: -100%;
      background: #FF337F;
      padding: 7px 28px 2px;
      border-radius: 24px 24px 0 24px;
      box-shadow: 0 6px 21px rgba(2, 2, 2, 0.3);
      transition: all .25s ease-in-out;
      ul {
        margin: 0;
        li {
          display: inline;
          a {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            padding: 0 8px;
          }
        }
      }
    }
    &:hover .team-member__socialmedia {
      right: 0;
    }
  }
}

.team-list-style3 {
  li {
    list-style: none;
    margin-bottom: 50px;
    .team-member {
      position: relative;
      padding: 0 20px;
      &__picture {
        img {
          border-radius: 50%;
          border: 20px solid #fff;
          box-shadow: 0 8px 21px #E1E7FF;
        }
      }
      &__info {
        padding: 63px 40px 20px;
        box-shadow: 0 8px 21px #E1E7FF;
        border-radius: 34px 34px 0 34px;
        margin-top: -40px;
      }
      &__socialmedia {
        display: inline;
        position: absolute;
        top: 10px;
        right: -10px;
        background: #FF337F;
        padding: 7px 28px 2px;
        border-radius: 24px 24px 0 24px;
        box-shadow: 0 6px 21px rgba(2, 2, 2, 0.3);
        transition: all .25s ease-in-out;
        ul {
          margin: 0;
          li {
            display: inline;
            a {
              color: #fff;
              font-size: 20px;
              font-weight: 400;
              padding: 0 8px;
            }
          }
        }
      }
      &:hover .team-member__socialmedia {
        right: 60px;
      }
    }
  }
}