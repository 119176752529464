//--------- accordion --------

.wpb-js-composer {
  /*----------------- style Flat -------------*/
  .vc_tta-style-flat {
    &.vc_tta-color-grey, &.vc_tta-color-blue, &.vc_tta-color-turquoise, &.vc_tta-color-pink, &.vc_tta-color-violet,
    &.vc_tta-color-peacoc, &.vc_tta-color-chino, &.vc_tta-color-mulled-wine, &.vc_tta-color-vista-blue, &.vc_tta-color-black,
    &.vc_tta-color-orange, &.vc_tta-color-sky, &.vc_tta-color-green, &.vc_tta-color-juicy-pink, &.vc_tta-sandy-brown,
    &.vc_tta-color-purple, &.vc_tta-color-white
    {
      .vc_tta-panels-container  {
        .vc_tta-panels div.vc_tta-panel.wd-tta-section {
          //-------- heading ----------
          .vc_tta-panel-heading {
            background: #fff;
            margin-bottom: 20px;
            border-radius: 30px 30px 0 30px !important;
            box-shadow: 0 3px 14px rgba(64, 124, 191,0.2);
            border: none;
            a {
              color: #666;
              font-weight: 300;
              padding: 20px 35px;
              i:before {
                border-color: #666;
              }
            }
          }
          &.vc_active, &:hover {
            .vc_tta-panel-heading {
              background: $primary-color;
              a {
                color: #fff;
                i:before {
                  border-color: #fff;
                }
              }

            }
          }
        //----------- content -------------
          .vc_tta-panel-body {
            background: #fff;
            margin: 20px 0;
            border-radius: 30px 30px 0 30px !important;
            box-shadow: 0 3px 20px rgba(64, 124, 191,0.2);
            padding: 40px 50px 80px 50px;
          }


        }
      }
    }
  }
/*------------ style Classic ------------*/
  .vc_tta-style-classic {
    &.vc_tta-color-grey, &.vc_tta-color-blue, &.vc_tta-color-turquoise, &.vc_tta-color-pink, &.vc_tta-color-violet,
    &.vc_tta-color-peacoc, &.vc_tta-color-chino, &.vc_tta-color-mulled-wine, &.vc_tta-color-vista-blue, &.vc_tta-color-black,
    &.vc_tta-color-orange, &.vc_tta-color-sky, &.vc_tta-color-green, &.vc_tta-color-juicy-pink, &.vc_tta-sandy-brown,
    &.vc_tta-color-purple, &.vc_tta-color-white
    {
      .vc_tta-panels-container  {
        .vc_tta-panels div.vc_tta-panel.wd-tta-section {
          .vc_tta-panel-heading {
            background: transparent;
            margin-bottom: 20px;
            border: none;
            border-bottom: 1px solid $primary-color ;
            a {
              color: $headings-color;
              font-weight: 300;
              i:before {
                border-color: #666;
              }
            }
          }
          &.vc_active, &:hover {
            .vc_tta-panel-heading {
              background: transparent;
              a {
                color: $primary-color;
              }
              i:before {
                border-color: $primary-color;
              }

            }
          }
          //----------- content -------------
          .vc_tta-panel-body {
            background: #fff;
            border: none;
            margin: 20px 0;
            padding: 15px 35px 20px 35px;
          }
        }
      }
    }
  }
}



