// button shortcode
.wd-btn {
  text-align: center;
  font-weight: bold;
  display: inline-block;
  transition: .4s all ease-in-out;
  text-transform: uppercase;
  cursor: pointer;
  will-change: background;
}

// color 1
.btn-color-1,
.hover-color-1:hover {
  &.btn-solid {
    color: #fff;
    background: $primary-color;
  }

  &.btn-border {
    color: $primary-color;
    border: 4px solid $primary-color;
    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }

  &.btn-underline {
    color: $primary-color;
  }

  &.btn-shadow {
    color: #fff;
    background: $primary-color;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }
}

// color 2
.btn-color-2,
.hover-color-2:hover {
  &.btn-solid {
    color: #fff;
    background: $accent-color;
  }

  &.btn-border {
    color: $accent-color;
    border: 4px solid $accent-color;
    &:hover {
      background: $accent-color;
      color: #fff;
    }
  }

  &.btn-underline {
    color: $accent-color;
  }

  &.btn-shadow {
    color: #fff;
    background: $accent-color;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    &:hover {
      background: $accent-color;
      color: #fff;
    }
  }
}

// color 3
.btn-color-3,
.hover-color-3:hover {
  &.btn-solid {
    color: #fff;
    background: $headings-color;
  }

  &.btn-border {
    color: $headings-color;
    border: 4px solid $headings-color;
    &:hover {
      background: $headings-color;
    }
  }

  &.btn-underline {
    color: $headings-color;
  }

  &.btn-shadow {
    color: #fff;
    background: $headings-color;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    &:hover {
      background: $headings-color;
    }
  }
}

// color 4
.btn-color-4,
.hover-color-4:hover {
  &.btn-solid {
    color: #fff;
    background: #262626;
  }

  &.btn-border {
    color: #262626;
    border: 4px solid #262626;
    &:hover {
      background: #262626;
    }
  }

  &.btn-underline {
    color: #262626;
  }

  &.btn-shadow {
    color: #fff;
    background: #262626;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    &:hover {
      background: #262626;
    }
  }
}

// color 5
.btn-color-5,
.hover-color-4:hover {
  &.btn-solid {
    color: #262626;
    background: #FFFFFF;
  }

  &.btn-border {
    color: #FFFFFF;
    border: 4px solid #FFFFFF;
    &:hover {
      background: #FFFFFF;
    }
  }

  &.btn-underline {
    color: #FFFFFF;
  }

  &.btn-shadow {
    color: $primary-color;
    background: #FFFFFF;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    &:hover {
      background: #FFFFFF;
    }
  }
}

///// Exception for underline style
a.wd-btn.btn-underline {
  line-height: unset;
  height: auto;
  border-bottom: 2px solid;
  border-radius: 0;
  padding: 0 0 2px;
}

/////// button size

// Big
.wd-btn.btn-big {
  line-height: 2;
  font-size: 15px;
  padding: 18px 48px 13px;
  &.btn-border {
    padding: 14px 48px 9px;
  }
}

// medium
.wd-btn.btn-medium {
  line-height: 2em;
  font-size: 13px;
  padding: 11px 36px 9px;
  &.btn-border {
    padding: 7px 36px 5px;
  }
}

// small
.wd-btn.btn-small {
  line-height: 2;
  font-size: 12px;
  padding: 9px 30px 7px;
  &,
  &:hover {
    border-width: 3px;
  }
  &.btn-border {
    padding: 6px 30px 4px;
  }
}

////// button border radius

//Round
.wd-btn.btn-round {
  border-radius: 32px 32px 0 32px;
}

// Radius
.wd-btn.btn-radius {
  border-radius: 5px;
}

// boder none
.wd-btn.btn-none {
  border-radius: 0;
}


// icon hover effect

.wd-btn {
  i.after {
    margin-left: 10px;
    transition: all ease-in-out 400ms;
    transform: translateX(0);
  }
  i.before {
    margin-right: 7px;
    transition: all ease-in-out 400ms, color 0s;
    transform: translateX(0);
  }
  &.icon-hs-2.icon-after {
    .button-wrp {
      position: relative;
      padding-right: 25px;
      display: block;
      transition: all .35s ease-in-out;
      i {
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
      }
      i.after {
        opacity: 1;
        right: 0;
      }
      i.before {
        opacity: 0;
        left: -100px;
      }
    }
  }
  &.icon-hs-2.icon-before {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    .button-wrp {
      position: relative;
      padding-left: 30px;
      display: block;
      transition: all .35s ease-in-out;
    }
    i {
      position: relative;
      top: 0;
    }
    i.after {
      opacity: 0;
      right: -100px;
    }
    i.before {
      opacity: 1;
      left: 0;
    }
  }
  &:hover {
    &.icon-hs-1 {
      i.after {
        transform: translateX(5px);
      }
      i.before {
        transform: translateX(-10px);
      }
    }
    &.icon-hs-2.icon-after {
      .button-wrp {
        padding-right: 0;
        padding-left: 25px;
      }
      i.after {
        opacity: 0;
        right: -100px;
      }
      i.before {
        opacity: 1 !important;
        left: 0;
      }
    }
    &.icon-hs-2.icon-before {
      .button-wrp {
        padding-right: 30px;
        padding-left: 0;
      }
      i.after {
        opacity: 1 !important;
        right: 0;
      }
      i.before {
        opacity: 0;
        left: -100px;
      }
    }
  }
  &.icon-hs-1 {
    .before {
      display: none;
    }
  }
}
