
@include breakpoint(small only) {
	.show-cart-btn {
		display: none;
	}
}

@media (max-width: 768px) {
	.header_top_lang {
		.contact-info {
			float: left !important;
			width: 100%;
			padding: 0 10px;
			i {
				padding-left: 0;
			}
			span {
				padding-right: 20px;
				&:last-child {
					padding-right: 0;
				}
			}
			.fa-phone {
				margin-left: 0 !important;
			}
		}
	}
	.titlebar {
		.breadcrumb_box {
			float: none !important;
			text-align: left;
			.breadcrumbs {
				float: none;
				li {
					float: none;
					display: inline;
				}
			}
		}
	}
	.boxes.small {
		.box-container {
			text-align: center;
			.box-icon, .box-title-1 {
				text-align: center;
			}
			.box-icon {
				float: none;
			}
		}
	}
}
