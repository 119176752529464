/*------------------ Layout ----------------*/
.shop-sidebar {
  //order: 2;
  @include breakpoint(large up) {
    padding-left: 50px;
  }
}

@include breakpoint(large up) {
  .woocommerce {
    .woocommerce-cart-form {
      width: 60%;
      float: left;
    }
    .cart-collaterals,
    .cart-collaterals {
      width: 35%;
      float: right;
      .cart_totals {
        width: 100%;
      }
    }
    .titlebar {
      margin-bottom: 70px !important;
    }
  }
}

.woocommerce ul.products li.product a img {
  margin: 0;
}

//____________sidebar
.widget.woocommerce {
  margin-bottom: 80px;
}

.widget-area, .wpb_widgetised_column {
  ul.product_list_widget {
    li {
      padding: 0;
      transition: all .3s ease;
      &:hover {
        //background: rgba($primary-color, .04);
        box-shadow: 0 0 40px 0 rgba(1,1,1,0.1), 0 0 0px 1px rgba($primary-color, .1);
        transform: scale(1.05);
        z-index: 9;
      }
      a {
        img {
          margin-right: 10px;
          width: 80px;
        }
        .product-title {
          color: $header-color;
          display: block;
          transform: translateY(15px);
          font-size: 15px;
        }
      }
      .amount {
        margin: 0;
        color: #3C3950;
        display: inline-block;
        transform: translateY(15px);
      }
      .star-rating {
        margin: 5px 0;
        span {
          &:before {
            @extend .primary-color_color;
          }
        }
      }
      .reviewer {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .widget-title {
    margin-bottom: 15px;
  }
  ul {
    list-style: square;
    li {
      padding: 5px 0;
      position: relative;
      &:last-child {
        border: none;
      }
      &.current-cat-parent > a {
        font-weight: bold;
      }
      &.current-cat > a {
        font-weight: bold;
        @extend .primary-color_color;
      }
      > a .child-indicator {
        &::after {
          content: '\f078';
          display: block;
          font-family: fontawesome;
          font-size: 10px;
          position: absolute;
          top: 1px;
          right: 0px;
          width: 13px;
          height: 24px;
        }
      }
    }

    a {
      color: #444;
    }
  }
  .product-categories {
    list-style: none;
    margin: 0;
    li.cat-item {
      &:last-child {
        padding-bottom: 0;
      }
      a {
        display: block;
        transition: all .2s ease-in .05s;
        padding-left: 20px;
        position: relative;
        color: #4D4D4D;
        margin-left: 10px;
        font-weight: 400;
        text-decoration: none;
        &:before {
          content: "";
          width: 10px;
          height: 4px;
          background: #E5E5E5;
          position: absolute;
          top: 9px;
          left: 0;
          transition: all .25s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        }
        &:hover {
          @extend .primary-color_color;
          padding-left: 30px;
          &:before {
            background: $accent-color;
            width: 2px;
            left: 18px;
            top: 5px;
            height:12px;
          }
        }
      }
      &.cat-parent {
        a {
          margin-bottom: 10px;
          &:before {
            position: absolute;
            top: 50%;
            right: 0;
            margin: 0;
          }
        }
        .children {
          list-style: none;
          li {
            padding-bottom: 5px;
            a {
              margin-bottom: 0;
              &:before {

              }
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
      &.current-cat {
        a {
          &:before {
            background: $accent-color;
            border: 0;
          }
        }
      }
    }
    span {
      float: right;
    }
  }
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  background: $accent-color;
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: #E4E2E3;
  height: 3px;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  width: 12px;
  height: 12px;
  top: -4px;
  background: $accent-color;
}

.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img {
  float: left;
  margin: 0 15px 0 0;
  width: 78px;
}

.woocommerce-product-search {
  .search-field {
    height: 50px;
  }
  input[type="submit"] {
    background: #000;
    height: 40px;
    width: 100%;
    color: #fff;
    font-weight: bold;
    border: none;
  }
}

.hidden-cart {
  .product_list_widget {
    display: table;
  }
}

//________content _________________________
p.woocommerce-result-count {
  color: #333;
  margin: 0 !important;
  font-family: $body-font-family;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;
  padding-top: 7px;
  @include breakpoint(small only) {
    float: none !important;
    padding-top: 0;
  }
}

.orderby {
  width: 270px;
  height: 60px;
  font-size: 14px;
  color: #333333;
  background: #fff;
}

.woocommerce .woocommerce-ordering {
  //position: relative;
  margin-bottom: 0;
  margin-right: 110px;
  right: 147px;
  top: 2px;
  position: absolute;
  @include breakpoint(small only) {
    float: left;
    width: calc(100% - 110px);
    margin-right: 0;
  }
  &:after {
    content: "\f078";
    font-family: "FontAwesome";
    color: #000;
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 11px;
    transform: translateY(-50%);
  }

  select {
    font-size: 13px;
    font-weight: 700;
    text-transform: capitalize;
    vertical-align: middle;
    border: none;
    margin-bottom: 0;
    height: 35px;
    font-family: $body-font-family;
    @include breakpoint(small only) {
      width: 100%;
    }
    &:focus {
      background: #fff;
    }
  }
}

.woocommerce {
  ul {
    &.products {
      &.list {
        .product.type-product.status-publish.has-post-thumbnail {
          margin: 0 0 50px !important;
          transition: none;
          @include breakpoint(small only) {
            padding: 0 15px;
          }
          a {
            .price {
              float: right;
              text-align: left;
              height: auto;
              bottom: auto;
              margin-top: 0;
              margin-bottom: 20px;
              @include breakpoint(small only) {
                width: 100%;
                float: none;
              }
              .woocommerce-Price-amount {
                &.amount {
                  margin: 0;
                }
              }
            }
            img {
              @include breakpoint(small only) {
                width: 100% !important;
                margin-right: 0 !important;
                margin-bottom: 20px;
              }
            }
          }
          .gridlist-buttonwrap {
            margin-bottom: 0;
            @include breakpoint(small only) {
              width: 100%;
              float: none;
            }
            .button {
              opacity: 1;
              position: unset;
              top: 0;
              width: 160px;
              height: auto;
              left: auto;
              right: auto;
              transform: none;
              bottom: auto;
              display: inline-block;
            }
          }
          &:not(.product-category):hover {
            box-shadow: none;
            padding-bottom: 0;
            margin-bottom: 50px !important;
          }
          .woocommerce-product-details__short-description {
            p {
              font-size: 14px;
              color: #A2A2A2;
              line-height: 23px;
              font-weight: 400;
            }
            @include breakpoint(small only) {
              width: 100%;
              float: none;
            }
          }
        }
      }
    }
  }
  .gridlist-toggle {
    height: 35px;
    right: 8px;
    top: 2px;
    position: absolute;
    a {
      height: 100%;
      line-height: 32px !important;
      width: 50px;
      background-color: #FFF;
      transition: all .3s ease-in-out;
      @extend .primary-color_color;
      span {
        line-height: 7px;
      }
      &.active {
        background-image: none;
        background-color: $primary-color;
        color: #FFF;
      }
      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
}

.woocommerce ul.products li.product span.onsale, .woocommerce-page ul.products li.product span.onsale {
  margin: 0;
  top: 3px;
  right: 3px !important;
  left: auto !important;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: $primary-color;
  justify-content: center;
  text-transform: uppercase;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
  z-index: 3;
}

.woocommerce ul.products li.product .onsale, .woocommerce-page ul.products li.product .onsale {
  font-weight: 500;
  color: #fff;
  background: #6c00ce;
  border-radius: 0;
  padding: 2px 10px;
  line-height: 18px;
  min-width: unset;
  min-height: unset;
  right: auto !important;
  left: 0 !important;
  transition: all .35s ease-in-out;
  font-size: 13px;
  @include breakpoint(small only) {
    left: auto !important;
  }
}

.woocommerce ul.products li.product:hover .onsale {
  left: -90px;
}

.woocommerce ul.products li.product h3 {
  font-size: 14px;
  color: #222222;
}

.price {
  ins {
    @extend .primary-color_color;
    text-decoration: none;
  }
}

.woocommerce ul.products li.product .price {
  @extend .primary-color_color;
  font-size: 15px;
  font-weight: 700;
}

.woocommerce ul.products li.product .price del {
  display: inline-block;
  margin-right: 10px;
  color: #000;
  float: none;
}

.woocommerce .products .star-rating {
  margin-left: 37%;
}

.woocommerce ul.products li.product {
  overflow: hidden;
  text-align: center;
  /* box-shadow: 0 3px 30px 0 rgba(0,0,0,0.03); */
  background-color: transparent;
  border: 1px solid #e9eeff;
  border-radius: 6px;
  a {
    h2.woocommerce-loop-category__title {
      position: absolute;
      bottom: 17px;
      font-family: $body-font-family;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: left;
      left: 18px;
      width: 86%;
      mark {
        float: right;
        @extend .primary-color_color;
        background: transparent;
      }
    }
    h2.woocommerce-loop-product__title {
      color: #000;
      margin-top: 10px;
    }
    .star-rating {
      display: none;
    }
    &:before {
      display: none !important;
    }
  }
  &:not(.product-category):hover {
    box-shadow: 0 0 66px 0 rgba(1,1,1,0.1);
    transform: scale(1.1);
    @include breakpoint(small only) {
      margin-bottom: 30px !important;
    }
    .button {
      opacity: 1;
    }
  }
  .yith-wcwl-add-to-wishlist > div {
    display: block;
    position: absolute;
    top: 10px;
    left: -30px;
    transition: all .5s;
  }
  .yith-wcwl-add-to-wishlist {
    .yith-wcwl-wishlistexistsbrowse {
      a {
        &::before {
          content: '';
        }
        &::after {
          content: '';
        }
        &:hover {
          &::before {
            content: 'View Wishlist';
            position: absolute;
            font-size: 10px;
            top: 1px;
            @extend .primary-color_bg;
            line-height: 24px;
            color: #fff;
            width: 100px;
            left: 30px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 2px;
            height: 24px;
          }
          &::after {
            content: '';
            position: absolute;
            top: 7px;
            left: 20px;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-right: 10px solid $primary-color;
            @extend .primary-color_border;
            border-bottom: 6px solid transparent;
            margin-top: 0;
          }
        }
      }
    }
    .yith-wcwl-add-button {
      a.add_to_wishlist {
        &::before {
          content: '';
        }
        &::after {
          content: '';
        }
        &:hover {
          &::before {
            content: 'Wishlist';
            position: absolute;
            font-size: 10px;
            top: 1px;
            @extend .primary-color_bg;
            line-height: 24px;
            color: #fff;
            width: 90px;
            left: 30px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 2px;
            height: 24px;
          }
          &::after {
            content: '';
            position: absolute;
            top: 7px;
            left: 20px;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-right: 10px solid $primary-color;
            @extend .primary-color_border;
            border-bottom: 6px solid transparent;
            margin-top: 0;
          }
        }
      }
    }
  }
  .button {
    padding: 10px 30px;
    border-radius: 30px;
    font-size: 12px;
    width: calc(100% - 15px);
    position: absolute;
    bottom: -40px;
    left: 50%;
    opacity: 0;
    transition: all .4s ease-out .2s;
    transform: translateX(-50%);
    font-family: $body-font-family;
    letter-spacing: .5px;
    margin: 0;
    @extend .primary-color_bg;
    @extend .primary-color_border;
    color: #fff;

    &.quick_view {
      top: 10px;
      bottom: auto;
      width: 30px;
      padding: 0;
      font-size: 8px;
      right: -20px;
      left: auto;
      background: none;
      border: none;
      &::after {
        content: "\f002";
        font-family: fontawesome;
        font-size: 16px;
        line-height: 28px;
        @extend .primary-color_color;
      }
    }
  }
  &:hover .button {
    bottom: 13px;
  }
  &.sale {
    position: relative;
    .promo-value {
      position: absolute;
      background: $accent-color;
      height: 50px;
      width: 135px;
      top: -5px;
      left: 8px;
      transform: translateX(-50%) rotate(-45deg);
      display: flex;
      align-items: center;
      justify-content: center;
      .the-value {
        transform: translateX(-50%) rotate(45deg);
        color: #fff;
        position: absolute;
        left: 75px;
        top: 25px;
        font-size: 11px;
      }
    }
  }
}

.remodal-wrapper {
  .remodal {
    box-shadow: none;
  }
}

.woocommerce ul.products li.product > a {
  display: block;
  transition: all .5s ease-in-out;
}


.woocommerce ul.products {
  margin: 0 -10px;
  li {
    transition: all .4s ease-in-out;
    &:hover {
      box-shadow: 0 0 6px 0 rgba(1, 1, 1, .3);
    }
    a:first-child {
      h3, .star-rating, .price, button {
        visibility: visible;
        opacity: 1;
        transition: opacity 400ms ease-in-out;
      }
      button {
        background: #393939;
        color: #ededed;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 0;
        padding: 15px 30px;
      }

    }
  }
}


.woocommerce-pagination {

  .page-numbers {
    @extend .wd-post__pagination;
    &.page-numbers {
      border: none;
      li {
        border: none;
        span.page-numbers,a.page-numbers {
          padding: 15px 19px;
        }
      }
    }

  }



}
.woocommerce {
  .woocommerce-cart-form{
    margin-top: 50px;
  }
  .titlebar {
    .woocommerce-breadcrumb {
      a {
        color: #253953;
      }
    }
  }
  .entry-summary {
    padding: 0 15px;
    text-align: left;
    .description {
      border-top: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
      padding: 30px 0 10px;
      margin: 10px 0 40px;
    }
    .cart {
      .single_add_to_cart_button {
        @extend .wd-btn;
        @extend .btn-solid;
        @extend .btn-color-2;
        @extend .hover-color-1;
        @extend .btn-round;
        margin-left: 15px;
        text-transform: none;
      }
    }
    .price {
      span {
        font-weight: 700;
        font-size: 20px;
        color: $accent-color;
      }
      ins {
        span {
          color: $accent-color;
        }
      }
      del {
        span {
          font-size: 16px;
          margin-right: 5px;
          color: #001774;
        }
      }
    }
    .quantity .qty {
      border-radius: 30px;
      width: 100px;
      height: 40px;
      border: 1px solid $primary-color;
      background-color: rgba(255, 255, 255, .34);
      padding: 0 0 0 15px;
    }
    .woocommerce-product-details__short-description {
      p {
        font-size: 14px;
        color: #A2A2A2;
        line-height: 23px;
        font-weight: 400;
      }
    }
  }
  .woocommerce-breadcrumb {
    a {
      color: #222;
    }
  }
  .woocommerce-product-gallery {
    &.images {
      padding: 0 15px;
    }
  }
  ul.products {
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .product.type-product.status-publish.has-post-thumbnail {
      &.featured {
        @include breakpoint(small only) {
          width: 100%;
          margin: 15px 0 !important;
        }
        a img {
          filter: brightness(0.984)  hue-rotate(100deg);
        }
        .featured-text {
          position: absolute;
          background: $accent-color;
          height: 78px;
          width: 125px;
          top: -37px;
          left: 30px;
          transform: translateX(-52%) rotate(-22deg);
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 11px 25px 0 rgba(0, 0, 0, 0.07);
          z-index: 4;
          border-radius: 40%;
          .the-text {
            transform: translateX(-60%) rotate(21deg);
            color: #fff;
            position: absolute;
            left: 69px;
            top: 48px;
            font-size: 13px;
          }
        }
        span.price {
          display: inline;
        }
        &.sale {
          span.price {
            .amount {
              position: relative;
            }
          }
          .promo-value {
            display: none;
          }
        }
      }
    }
  }
}

.woocommerce div.product .product_title {
  font-size: 20px;
  font-weight: 800;
  color: #2E2E2E;
}

//_________single product ________________
.single-product {
  .wd-header {
    position: unset;
  }
  .entry-summary {
    .yith-wcwl-add-button {
      .add_to_wishlist {
        @extend .primary-color_bg;
        color: #FFF;
        padding: 12px 25px;
        display: inline-block;
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}

.single-product.woocommerce {
  span.onsale {
    position: absolute;
    margin: 0;
    top: 0;
    right: 55% !important;
    left: auto !important;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    background-image: linear-gradient(180deg, #4f25a5, #cd27a0);
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: uppercase;
    box-shadow: 0 20px 19px 0 #f7c5ff;
    border: 1px solid #707070;
  }
  .woocommerce-review-link {
    margin-left: 20px;
    color: #000;
    text-transform: capitalize;
  }
  .product_meta {
    & > span {
      display: block;
      margin-bottom: 10px;
    }

    .sku_wrapper {
      span {
        font-weight: bold;
        color: #000;
      }
    }
    .posted_in {
      a {
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
        margin: 0 5px;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }
  .variations {
    background: none;
    .label {
      background: none;
      label {
        color: #000;
        text-transform: uppercase;
        font-size: 15px;
      }
    }
    .value {
      select {
        height: 40px;
      }
    }
  }
}

@include breakpoint(large up) {
  .woocommerce div.product div.images {
    margin-bottom: 0;
    border: 0;
    width: 55%;
    margin-right: 3%;
  }
  body.woocommerce div.product div.summary {
    width: 42%;
  }
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
  list-style-type: none;

  li {
    background-color: transparent;
    border: none;
    padding: 10px 30px 0 10px !important;
    margin: 0;
    &.active {
      padding: 10px 30px 0 10px !important;
      margin: 0;
    }
  }
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:after,
.woocommerce div.product .woocommerce-tabs ul.tabs li:before {
  content: none;
}

.woocommerce div.product .woocommerce-tabs .panel {
  padding: 20px;
  background: none;
  border: none;
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.woocommerce div.product .woocommerce-tabs ul.tabs:before {
  border: none;
}

.related.products {
  width: 100%;
  > h2 {
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 50px;
    display: inline-block;
    @extend h4;
    @include breakpoint(small only) {
      padding-left: 15px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      border-bottom: 1px solid $primary-color;
      bottom: -1px;
    }
  }
  li.product .button {
    border: none;
  }

}

.woocommerce div.product .woocommerce-tabs {
  ul.tabs {
    li {
      background: none;
      border: none;
      a {
        color: $headings-color;
        font-size: 16px;
        &:after {
          background: #cccccc;
          content: "";
          display: inline-block;
          height: 25px;
          margin-left: 15px;
          position: absolute;
          top: 18px;
          width: 1px;

        }
      }
      &.active a,
      a:hover {
        color: $primary-color;
      }
      &:last-child {
        a {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
  margin-bottom: 0;
  margin-top: 30px;
}
.single-product {
  #tab-description,
  #tab-reviews {
    background: none;
    text-align: left;
    margin-bottom: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    h2 {
      font-size: 15px;

    }
  }
}

#reviews #comments {
  margin-top: 20px;
}
#review_form {
  margin-top: 40px;
  .comment-reply-title {
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
  }
}

//_______tcheckout ________
#order_review_heading {
  font-size: 20px;
}

#order_review {
  background: #f2f2f2;
  padding: 20px;
  .shop_table {
    border: none;
    background: transparent;
    thead, tfoot {
      background: transparent;
    }
    thead {
      .product-name, .product-total {
        border-bottom: 1px solid #ccc;
        color: #999;
      }
    }
    tbody {
      tr {
        background: transparent;
        td {
          border: none;
        }
      }
    }
  }
}

.woocommerce-checkout #payment {
  background: none;
}

.place-order {
  input#place_order {
    width: 100%;
    background: #000;
    color: #fff;
  }
}

.woocommerce-billing-fields {
  h3 {
    font-size: 20px;
  }

}

table tr.even, table tr.alt, table tr:nth-of-type(2n) {
  background: none;
}

//______________shopping cart_________________
.wd-cart-total {
  .calculated_shipping {
    h2 {
      font-size: 20px;
      margin: 0;
      margin-bottom: 20px;
    }
    .shop_table {
      background: #000;
      padding: 20px;
      border: none;
      border-radius: 0;
      tr, th, span {
        color: #fff;
      }

    }
  }
  .wc-proceed-to-checkout {
    a.checkout-button {
      background: #cc9900;
      color: #fff;
      border-radius: 0;
      &:hover {
        background: #bf9107;
      }
    }
  }

}

.woocommerce {
  .shop_table.cart {
    border: 0;
    border-radius: 0;
    img {
      width: 100px;
    }
    thead {
      background: none;
      border: 0;
      display: none;
    }
    tbody {
      td {
        border: none;
      }
      .product-remove {
        a {
          color: #000 !important;
          &:hover {
            background: none;
            color: #000;
          }
        }

      }
      .product-name {
        a {
          color: #000;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .product-subtotal {
        font-size: 16px;
        font-weight: bold;
      }
      .quantity .qty {
        border-width: 0;
        border-bottom-width: 2px;
        background: none;
      }
    }
  }
  .cart-collaterals div.cart_totals {
    > h2 {
      font-size: 28px;
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 10px;
      margin-bottom: 40px;
    }
    table.shop_table {
      border: none;
    }
  }
}

.wd-header.wd-header-1 {
  .show-icons {
    > div:first-child {
      margin-right: 65px;
    }
  }
}

.wd-header.wd-header-2 {
  .show-icons {
    .wd-menu-left {
      width: 39%;
    }
    .wd-logo {
      width: 10%;
    }
    .wd-menu-right {
      width: 39%;
    }
    .min-search-cart {
      margin-top: 20px;
      width: 5%;
    }
  }
}

.wd-header.wd-header-4, .wd-header.wd-header-5 {
  .show-icons {
    .menu-main-menu-container {
      margin-right: 236px;
      @include breakpoint(small only) {
        margin-right: 0;
      }
    }
  }
}

.show-cart-btn {
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat;
  text-align: center;
  font-weight: bold;
  &::after {
    content: "\f07a";
    font-family: fontawesome;
    font-size: 24px;
    line-height: 22px;

  }
  span {
    font-size: 14px;
    color: #000;
  }
  span.min-cart-count {
    background-color: #cc9900;
    border-radius: 50%;
    font-size: 11px;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    color: #fff;
    line-height: 20px;
    text-align: center;
    margin-left: 15px;
  }
  .hidden-cart {
    background-color: #FFF;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 65px;
    width: 320px;
    z-index: 1;
    border-radius: 3px;
    border: 1px solid #EEE;
    li img {
      border: 1px solid #eee;
    }
    .woocommerce-mini-cart {
      li.mini_cart_item {
        margin-left: 0 !important;
        padding: 0;
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        .remove_from_cart_button {
          left: auto;
          right: 0;
          color: #333 !important;
          border: 1px solid #333;
          line-height: 15px;
          text-align: center;
          width: 15px;
          height: 15px;
          &:hover {
            background-color: #FFF;
          }
        }
      }
    }
    .widget_shopping_cart ul.cart_list li.empty {
      padding: 0;
    }
    .widget_shopping_cart {
      overflow: hidden;
      .woocommerce-mini-cart__buttons {
        a {
          @extend .primary-color_bg;
          color: #FFF !important;
        }
      }
    }
  }
}

.show-search-btn {
  position: absolute;
  right: -20px;
  cursor: pointer;
  background-repeat: no-repeat;
  text-align: center;
  font-weight: bold;
  top: 30px;
  span {
    color: #FFF;
    font-size: 24px;
  }
  .hidden-search {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    padding: 8px 14px 21px;
    position: absolute;
    right: 0;
    top: 50px;
    width: 300px;
    #searchform input {
      background-color: #c8c7c7;
      transition: all 0.6s, background-color .3s;
      color: #000;
      height: 36px;
      &:focus, &:active {
        background-color: #E7E7E7;
      }
    }
    #searchform input::-webkit-input-placeholder {
      color: #000000;
    }
    #searchform input:-moz-placeholder { /* Firefox 18- */
      color: #000000;
    }
    #searchform input::-moz-placeholder { /* Firefox 19+ */
      color: #000000;
    }
    #searchform input:-ms-input-placeholder {
      color: #000000;
    }
  }
}

.wd-header.wd-header-2 {
  .show-search-btn {
    margin-right: 106px;
  }
  .show-cart-btn {
    margin-right: 48px;
  }
  .languages_section {
    margin-right: 0;
    position: absolute;
    right: 0;
  }
}

.wd-header.wd-header-3 {
  .show-cart-btn {
    z-index: 1;
    margin-right: 90px;
  }
  .show-search-btn {
    z-index: 1;
    margin-right: 156px;
  }
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
  padding: 14px 22px 10px;
  color: #fff;
  background-color: $primary-color;
  &:hover {
    color: #fff;
  }
}

.woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
  width: 55%;
}

#add_payment_method table.cart input, .woocommerce-cart table.cart input, .woocommerce-checkout table.cart input {
  line-height: 1.4;
}

.woocommerce nav.woocommerce-pagination {
  text-align: center;
  margin: 0 0 20px 0;
}


.archive.woocommerce .columns ul.products li.product {
  @include breakpoint(small only) {
    width: 100%;
  }
}

@include breakpoint(small only) {
  .woocommerce ul.products li.product,
  .woocommerce-page ul.products li.product,
  .woocommerce-page[class*="columns-"] ul.products li.product,
  .woocommerce[class*="columns-"] ul.products li.product {
    width: 100%;
  }
}

.wd_product_cat {
  .product-cat-container {
    .thumnbail,
    .cat_text {
      display: table-cell;
      vertical-align: top;
    }
    .cat_text {
      padding-left: 20px;
      a {
        h3 {
          font-size: 15px;
          font-weight: 700;
          text-transform: uppercase;
          margin: 0;
          letter-spacing: .5px;
        }
      }
      ul {
        margin: 10px 0 0 0;
        li {
          list-style: none;
          padding-bottom: 5px;
          a {
            position: relative;
            color: #333;
            text-transform: capitalize;
            padding-left: 10px;
            &::before {
              transition: .5s ease-in-out;
              content: '\f105';
              font-family: "FontAwesome";
              font-size: 12px;
              color: #999;
              font-weight: 600;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
            &:hover::before {
              @extend .primary-color_color;
            }
          }
        }
      }
    }
  }
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger {
  top: 10px;
  right: 25px;
  border: 1px solid #c0b8b8;
}

.woocommerce div.product div.images .flex-control-thumbs {
  margin: 10px -5px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  li {
    padding: 0 3px;
    width: 11%;
    margin-top: 20px;
    img {
      border: 1px solid $primary-color;
      border-radius: 3px;
      &.flex-active {
        border-color: $accent-color;
      }
    }
  }
}

.woocommerce div.product div.images {
  .flex-viewport,
  .woocommerce-product-gallery__wrapper {
    box-shadow: 0 10px 50px rgba(4,75,194,0.1);
    border: 1px solid rgba(88,136,247,0.1);
    border-radius: 10px;
  }
}

.woocommerce ul.products li a:first-child .price {
  box-shadow: none;
  padding-bottom: 0;
  background-color: transparent;
  color: #484848;
}

.woocommerce ul.products li.first, .woocommerce-page ul.products li.first {
  clear: both;
}

.woocommerce {
  .row {
    @include breakpoint(small only) {
      display: block;
      .columns {
        &:last-child {
          margin-top: 40px;
        }
      }
    }
  }
}

.filter-options {
  position: relative;
  margin-bottom: 30px;
  background-color: #E9EEFF;
  padding: 5px 5px 5px 10px;
  border-radius: 2px;
  @include breakpoint(small only) {
    padding: 10px;
  }
  .filter-flex {
    overflow: hidden;
  }
}

.woocommerce .widget_price_filter .price_slider_amount .button {
  font-size: 12px;
  padding: 10px 25px 8px;;
  letter-spacing: .4px;
  border-radius: 15px 15px 0 15px;
  &:hover {
    background: $accent-color;
  }
}

.woocommerce {
  .price_label {
    color: #333;
    font-size: 13px;
  }
   a.button {
    color:#fff;
     &:hover {
       color:#fff;
     }
  }
}