
.first-footer {
  background-color: rgba(255, 255, 255, 1);
}

.first-footer, .first-footer .block-title, .first-footer ul li a, .first-footer h6 {
  color: rgba(255, 255, 255, 1)
}

.second-footer {
  background: rgba(58, 70, 209, 1);;
  color: rgba(255, 255, 255, 1);
}

.first-footer {
  background-image: url('../images/footer-bg.svg');
  background-size: cover;

}

.powered .block .menu li a,
.footer-social-media li a {
  color: rgba(255, 255, 255, 1);
}

.powered .block .menu li {
  border-right-color: rgba(255, 255, 255, 1);
}

.titlebar {
  background-repeat: no-repeat;
}

.titlebar #page-title {
  text-align: center;
}

.header-top.social_top_bar, .orange_bar {
  background: rgba(24, 27, 42, 1);;
}

.header-top.social_top_bar, .orange_bar,
.l-header .header-top .contact-info,
.l-header .header-top i,
.l-header .header-top .social-icons.accent li i,
#lang_sel_list a.lang_sel_sel, #lang_sel_list > ul > li a {
  color: rgba(255, 255, 255, 1);;
}

body, body p {
  font-size: 15px;;
  font-family: Martel Sans;;
  font-style: normal;;
  font-weight: 300;;
}

h1, h2, h3, h4, h5, h6, .menu-list a {
  font-family: Martel Sans;
  font-style: normal;
  font-weight: 600;
}

.top-bar .top-bar-right .menu li a {
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;

}

.primary-color_bg, .square-img > a::before, .boxes .box > a::before, .boxes .box .flipper a::before, .wd_onepost .title-block span, .one_post_box .box_image .titel_icon .box_icon, .one_post_box .more, .boxes .box-container > a::before, .boxes .box-container .flipper a::before, .layout-4 div.box-icon i.fa, .boxes.small.layout-5 .box-icon, .boxes.small.layout-5-inverse .box-icon, .boxes.small.layout-6 .box-icon i.fa, .carousel_blog span.tag a, .wd-carousel-container .carousel-icon i, .search_box input[type='submit'], table thead, table tfoot, .block-block-17, .row.call-action,
.blog-info, button.dark:hover, button.dark:focus, .button.dark:hover, .button.dark:focus, span.wpb_button:hover, span.wpb_button:focus, .woocommerce .widget_price_filter .ui-slider .ui-slider-range, .woocommerce-page .widget_price_filter .ui-slider .ui-slider-range, .products .product .button, .woocommerce #content input.button.alt, .woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce-page #content input.button.alt, .woocommerce-page #respond input#submit.alt, .woocommerce-page a.button.alt, .woocommerce-page button.button.alt, .woocommerce-page input.button.alt, .woocommerce #content input.button:hover, .woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover, .woocommerce-page #content input.button:hover, .woocommerce-page #respond input#submit:hover, .woocommerce-page a.button:hover, .woocommerce-page button.button:hover, .woocommerce-page input.button:hover, .woocommerce span.onsale, .woocommerce-page span.onsale, .woocommerce-page button.button, .widget_product_search #searchsubmit, .widget_product_search #searchsubmit:hover, .first-footer #searchsubmit, .page-numbers.current,
.post-password-form input[type='submit'], .page-links a:hover, .blog-post .sticky .blog-info, .team-member-slider .owl-dots .owl-dot.active span, .team-member-slider .owl-theme .owl-dots .owl-dot:hover span, .team-member-carousel .owl-dots .owl-dot.active span, .team-member-carousel .owl-theme .owl-dots .owl-dot:hover span, .wd-image-text.style-2 h4::after, .blog-posts .read-more a::after, .top-bar-section .request-quote a,
.contact-form [type='submit'], .woocommerce-page input.button, .woocommerce-page a.button {
  background: rgba(51, 92, 255, 1);
}

.text_icon_hover > div .vc_row:hover {
  background: rgba(51, 92, 255, 1) !important;
}

.text_icon_hover > div .vc_row:hover .box-title-1, .text_icon_hover > div .vc_row:hover .box-body {
  color: #fff;
}

.blog-post .sticky .blog-info,
.primary-color_bg, h2.contact-us::after, .contact-us-info h2::after, .who-we-are h2::after, input.wpcf7-submit, .sidebar-second.sidebar.sidebar-left ul li:hover,
.first-footer .newsletter-div .newslettersubmit, .first-footer h2::after, .square-img > a::before, .boxes .box > a::before,
.boxes .box .flipper a::before, .pricing-table.featured .special-box, .pricing-table.featured .special-box::before, .pricing-table.featured .special-box::after,
.pricing-table.featured .pricing-table-header, div.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-heading,
div.vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab.vc_active > a, .contact-form [type='submit'],
.format-quote .post-box .post-body-container, .format-link .post-box .post-body-container, .wd-image-text.style-2 h4::after, .wd_onepost .title-block span,
.one_post_box .box_image .titel_icon .box_icon, .one_post_box .more, .boxes .box-container > a::before, .boxes .box-container .flipper a::before,
.layout-4 div.box-icon i.fa, .boxes.small.layout-5 .box-icon, .boxes.small.layout-5-inverse .box-icon, .boxes.small.layout-6 .box-icon i.fa, table thead, table tfoot,
.block-block-17, .row.call-action, .blog-info, .wd-button:hover, .wd-button:focus, span.wpb_button:hover, span.wpb_button:focus {
  background: rgba(51, 92, 255, 1);
}

.sidebar #s:active,
.custom-tour .vc_tta-tabs-container li.vc_active a,
.boxes.layout-3 .box-icon, .top-bar-section ul li:hover, .corporate-layout .top-bar-section ul li:hover, .corporate-layout .top-bar-section ul li.current-menu-item,
.primary-color_border, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.custom-tour .vc_tta-tabs-container li.vc_active a, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.custom-tour .vc_tta-panel-body .container .img-wrp, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic.vertical-tab .vc_tta-tabs-container li.vc_active a, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic.vertical-tab .vc_tta-panel-body .container .img-wrp, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.vertical-accordion .vc_tta-panels-container .vc_tta-panel.vc_active .vc_tta-panel-title a, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.vertical-accordion .vc_tta-panel-body .container .img-wrp, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic.custom-accordion .vc_tta-panel.vc_active .vc_tta-panel-heading, .section-container.auto > section.active .title, .section-container.auto > .section.active .title, .section-container.vertical-tabs > section.active > .title, .section-container.vertical-tabs > .section.active > .title, .section-container.vertical-nav > section.active > .title, .section-container.vertical-nav > .section.active > .title, .section-container.horizontal-nav > section.active > .title, .section-container.horizontal-nav > .section.active > .title, .section-container.accordion > section.active > .title, .section-container.accordion > .section.active > .title {
  border-color: rgba(51, 92, 255, 1);
}

.blog-info .arrow {
  border-color: transparent rgba(51, 92, 255, 1);
}

.primary-color_color, a, a:focus, a.active, a:active, a:hover, section.corporate .menu-item a i,
.box-container:hover .box-title, .woocommerce .woocommerce-breadcrumb, .woocommerce-page .woocommerce-breadcrumb, div.boxes.small.layout-3 .box-icon i, .corporate-layout .header-top .contact-info .fa,
.l-header .header-top .social-icons.accent li:hover i, .corporate-layout .top-bar-section ul li:hover:not(.has-form) > a,
.corporate-layout .top-bar-section ul li.active:not(.has-form) > a:not(.button),
.creative-layout .top-bar-section li.active:not(.has-form) a:not(.button),
.team-member-item h3,
.layout-4-testimonials .testimonial-box .author-info .rating li.selected .fa,
.custom-tour .vc_tta-tabs-container li.vc_active a i,
.social_media li i,
.primary-color_color, .who-we-are h4, a, a:focus, a.active, a:active, a:hover, .contact-info i,
.sidebar .block-title::before, .first-footer .newsletter-div a.footer-readmor,
.first-footer ul li a::before, .pricing-table.featured .pricing-table-header .sign-up .button, .woocommerce-message::before,
.social_media li i, .team-member-item h3, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.custom-tour .vc_tta-tabs-container li.vc_active a i, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic.vertical-tab .vc_tta-tabs-container li.vc_active a i, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.vertical-accordion .vc_tta-panels-container .vc_tta-panel.vc_active .vc_tta-panel-title a i, .wd-testimonail blockquote cite, .layout-4-testimonials .testimonial-box .author-info .rating li.selected .fa, .boxes.small .box-icon i, .box-container:hover .box-title, .wd-pagination .page-numbers:hover {
  color: rgba(51, 92, 255, 1);
}

.boxes.small.layout-3 .box-icon i,
div.boxes.small.layout-3:hover .box-icon i {
  color: rgba(255, 255, 255, 1);
}

.blog-posts h2 a, .breadcrumbs > *, .breadcrumbs {
  color: rgba(58, 70, 209, 1)
}

.button, .button:hover, .button:focus, .products .product:hover .button,
.woocommerce-product-search > input[type='submit'] {
  background-color: rgba(58, 70, 209, 1)
}

.top-bar .top-bar-right .menu li a {
  color: rgba(255, 255, 255, 1);
}

header.l-header .top-bar-container .site-title-bar {
  background-color: rgba(58, 70, 209, 1);
}

header.l-header .top-bar-container .site-title-bar .menu-icon::after {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 7px 0 rgba(255, 255, 255, 1), 0 14px 0 rgba(255, 255, 255, 1);
}

@media only screen and (min-width: 64em) {
  header.l-header .top-bar-container.sticky.fixed, header.l-header .top-bar-container.sticky.fixed .site-navigation.top-bar {
    background-color: rgba(58, 70, 209, 1);
  }
  header.l-header.creative-layout .top-bar-right .menu li a:hover {
    color: rgba(255, 255, 255, 1);
  }
  header.l-header.creative-layout .top-bar-container.sticky.fixed .top-bar-right .menu > li > a:hover {
    color: rgba(255, 255, 255, 1);
  }
}

.first-footer, .first-footer .block-title, .first-footer ul li a {
  color: rgba(255, 255, 255, 1)
}

#spaces-main {
  background-color: rgba(255, 255, 255, 1)
}

.blog-info .arrow {
  border-left-color: rgba(51, 92, 255, 1);
}

.ui-accordion-header-active, .ui-tabs-active, .box-icon, .woocommerce-message {
  border-top-color: rgba(51, 92, 255, 1)
}

.top-header .__top-header-right p {
  color: rgba(255, 255, 255, 1);
}

.wd-heading .title_a {
  font-weight: 600;
}

.wd-heading .sub_title_a {
  font-weight: 300;
  color: rgba(51, 51, 51, 1);
}

.wd-heading .title_b {
  font-weight: 600;
}

.wd-heading .sub_title_b {
  font-weight: 300;
  font-size: 24px;
  color: rgba(140, 146, 172, 1);
  line-height: 1.66;
}

.wd-heading .title_c {
  font-weight: 600;
  color: rgba(46, 50, 91, 1);
}

.wd-heading .sub_title_c {
  font-weight: 300;
  font-size: 17px;
  color: rgba(140, 146, 172, 1);
  line-height: 28px;
}

.wd-heading .hr_a {
  border-bottom-style: solid;
}

.wd-heading .hr_b {
  border-bottom-style: solid;
}

.wd-heading .hr_c {
  border-bottom-style: solid;
}

.our-feature {
  .feature-boxs {
    margin-top: 32px;
    .boxes {
      background-color: #ffffff;
      border-radius: 35px;
    }
  }
}

.main-future {
  .boxes {
    background-color: #ffffff;
    border-radius: 35px;
  }
}

.need-quotes {
  background-image: url(../images/need-quote-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.animated-section {
  position: relative;
}

.about-testimonail {
  background-image: url(../images/Testimonial-1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ultimate-design {
  background: url(../images/Group-798-1.png) no-repeat 0 0;
}

.premium-quality {
  background-image: url(../images/Shape-2.png);
  background-repeat: no-repeat;
  background-position: -260px bottom;
  .boxes {
    background-color: #ffffff;
    border-radius: 25px;
  }
}

.premium-services {
  .boxes {
    background-position: bottom right !important;
  }
  .box-1 {
    padding-bottom: 170px;
    background-image: url(../images/Mask-Group-23.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    border-radius: 32px;
  }
  .box-2 {
    padding-bottom: 170px;
    background-image: url(../images/Mask-Group-25.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    border-radius: 32px;
  }
  .box-3 {
    padding-bottom: 170px;
    background-image: url(../images/Mask-Group-23.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    border-radius: 32px;
  }
  .box-4 {
    padding-bottom: 170px;
    background-image: url(../images/Mask-Group-23.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    border-radius: 32px;
  }
}

.home-4 {
  .first-footer {
    background-color: rgba(255, 255, 255, 1);
  }
  .first-footer, .first-footer .block-title, .first-footer ul li a, .first-footer h6 {
    color: #1B2229
  }
  .second-footer {
    background: #D4E5FD;;
    color: #1B2229;
  }
  .first-footer {
    background-image: url('../images/Path-1444.png');
    background-size: cover;

  }

  .powered .block .menu li a,
  .footer-social-media li a {
    color: #1B2229;
  }
  .powered .block .menu li {
    border-right-color: #1B2229;
  }

  .titlebar {
    background-repeat: no-repeat;
  }
  .titlebar #page-title {
    text-align: center;
  }
  .header-top.social_top_bar, .orange_bar {
    background: rgba(24, 27, 42, 1);;
  }
  .header-top.social_top_bar, .orange_bar,
  .l-header .header-top .contact-info,
  .l-header .header-top i,
  .l-header .header-top .social-icons.accent li i,
  #lang_sel_list a.lang_sel_sel, #lang_sel_list > ul > li a {
    color: rgba(255, 255, 255, 1);;
  }
  body, body p {
    font-size: 15px;;
    font-family: Martel Sans;;
    font-style: normal;;
    font-weight: 300;;
  }
  h1, h2, h3, h4, h5, h6, .menu-list a {
    font-family: Martel Sans;
    font-style: normal;
    font-weight: 600;
  }
  .top-bar .top-bar-right .menu li a {
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;

  }
  .primary-color_bg, .square-img > a::before, .boxes .box > a::before, .boxes .box .flipper a::before, .wd_onepost .title-block span, .one_post_box .box_image .titel_icon .box_icon, .one_post_box .more, .boxes .box-container > a::before, .boxes .box-container .flipper a::before, .layout-4 div.box-icon i.fa, .boxes.small.layout-5 .box-icon, .boxes.small.layout-5-inverse .box-icon, .boxes.small.layout-6 .box-icon i.fa, .carousel_blog span.tag a, .wd-carousel-container .carousel-icon i, .search_box input[type='submit'], table thead, table tfoot, .block-block-17, .row.call-action,
  .blog-info, button.dark:hover, button.dark:focus, .button.dark:hover, .button.dark:focus, span.wpb_button:hover, span.wpb_button:focus, .woocommerce .widget_price_filter .ui-slider .ui-slider-range, .woocommerce-page .widget_price_filter .ui-slider .ui-slider-range, .products .product .button, .woocommerce #content input.button.alt, .woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce-page #content input.button.alt, .woocommerce-page #respond input#submit.alt, .woocommerce-page a.button.alt, .woocommerce-page button.button.alt, .woocommerce-page input.button.alt, .woocommerce #content input.button:hover, .woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover, .woocommerce-page #content input.button:hover, .woocommerce-page #respond input#submit:hover, .woocommerce-page a.button:hover, .woocommerce-page button.button:hover, .woocommerce-page input.button:hover, .woocommerce span.onsale, .woocommerce-page span.onsale, .woocommerce-page button.button, .widget_product_search #searchsubmit, .widget_product_search #searchsubmit:hover, .first-footer #searchsubmit, .page-numbers.current,
  .post-password-form input[type='submit'], .page-links a:hover, .blog-post .sticky .blog-info, .team-member-slider .owl-dots .owl-dot.active span, .team-member-slider .owl-theme .owl-dots .owl-dot:hover span, .team-member-carousel .owl-dots .owl-dot.active span, .team-member-carousel .owl-theme .owl-dots .owl-dot:hover span, .wd-image-text.style-2 h4::after, .blog-posts .read-more a::after, .top-bar-section .request-quote a,
  .contact-form [type='submit'], .woocommerce-page input.button, .woocommerce-page a.button {
    background: rgba(51, 92, 255, 1);
  }
  .text_icon_hover > div .vc_row:hover {
    background: rgba(51, 92, 255, 1) !important;
  }
  .text_icon_hover > div .vc_row:hover .box-title-1, .text_icon_hover > div .vc_row:hover .box-body {
    color: #fff;
  }
  .blog-post .sticky .blog-info,
  .primary-color_bg, h2.contact-us::after, .contact-us-info h2::after, .who-we-are h2::after, input.wpcf7-submit, .sidebar-second.sidebar.sidebar-left ul li:hover,
  .first-footer .newsletter-div .newslettersubmit, .first-footer h2::after, .square-img > a::before, .boxes .box > a::before,
  .boxes .box .flipper a::before, .pricing-table.featured .special-box, .pricing-table.featured .special-box::before, .pricing-table.featured .special-box::after,
  .pricing-table.featured .pricing-table-header, div.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-heading,
  div.vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab.vc_active > a, .contact-form [type='submit'],
  .format-quote .post-box .post-body-container, .format-link .post-box .post-body-container, .wd-image-text.style-2 h4::after, .wd_onepost .title-block span,
  .one_post_box .box_image .titel_icon .box_icon, .one_post_box .more, .boxes .box-container > a::before, .boxes .box-container .flipper a::before,
  .layout-4 div.box-icon i.fa, .boxes.small.layout-5 .box-icon, .boxes.small.layout-5-inverse .box-icon, .boxes.small.layout-6 .box-icon i.fa, table thead, table tfoot,
  .block-block-17, .row.call-action, .blog-info, .wd-button:hover, .wd-button:focus, span.wpb_button:hover, span.wpb_button:focus {
    background: rgba(51, 92, 255, 1);
  }
  .sidebar #s:active,
  .custom-tour .vc_tta-tabs-container li.vc_active a,
  .boxes.layout-3 .box-icon, .top-bar-section ul li:hover, .corporate-layout .top-bar-section ul li:hover, .corporate-layout .top-bar-section ul li.current-menu-item,
  .primary-color_border, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.custom-tour .vc_tta-tabs-container li.vc_active a, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.custom-tour .vc_tta-panel-body .container .img-wrp, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic.vertical-tab .vc_tta-tabs-container li.vc_active a, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic.vertical-tab .vc_tta-panel-body .container .img-wrp, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.vertical-accordion .vc_tta-panels-container .vc_tta-panel.vc_active .vc_tta-panel-title a, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.vertical-accordion .vc_tta-panel-body .container .img-wrp, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic.custom-accordion .vc_tta-panel.vc_active .vc_tta-panel-heading, .section-container.auto > section.active .title, .section-container.auto > .section.active .title, .section-container.vertical-tabs > section.active > .title, .section-container.vertical-tabs > .section.active > .title, .section-container.vertical-nav > section.active > .title, .section-container.vertical-nav > .section.active > .title, .section-container.horizontal-nav > section.active > .title, .section-container.horizontal-nav > .section.active > .title, .section-container.accordion > section.active > .title, .section-container.accordion > .section.active > .title {
    border-color: rgba(51, 92, 255, 1);
  }
  .blog-info .arrow {
    border-color: transparent rgba(51, 92, 255, 1);
  }
  .primary-color_color, a, a:focus, a.active, a:active, a:hover, section.corporate .menu-item a i,
  .box-container:hover .box-title, .woocommerce .woocommerce-breadcrumb, .woocommerce-page .woocommerce-breadcrumb, div.boxes.small.layout-3 .box-icon i, .corporate-layout .header-top .contact-info .fa,
  .l-header .header-top .social-icons.accent li:hover i, .corporate-layout .top-bar-section ul li:hover:not(.has-form) > a,
  .corporate-layout .top-bar-section ul li.active:not(.has-form) > a:not(.button),
  .creative-layout .top-bar-section li.active:not(.has-form) a:not(.button),
  .team-member-item h3,
  .layout-4-testimonials .testimonial-box .author-info .rating li.selected .fa,
  .custom-tour .vc_tta-tabs-container li.vc_active a i,
  .social_media li i,
  .primary-color_color, .who-we-are h4, a, a:focus, a.active, a:active, a:hover, .contact-info i,
  .sidebar .block-title::before, .first-footer .newsletter-div a.footer-readmor,
  .first-footer ul li a::before, .pricing-table.featured .pricing-table-header .sign-up .button, .woocommerce-message::before,
  .social_media li i, .team-member-item h3, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.custom-tour .vc_tta-tabs-container li.vc_active a i, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic.vertical-tab .vc_tta-tabs-container li.vc_active a i, .wpb-js-composer .vc_tta-container div.vc_tta-color-grey.vc_tta-style-classic.vertical-accordion .vc_tta-panels-container .vc_tta-panel.vc_active .vc_tta-panel-title a i, .wd-testimonail blockquote cite, .layout-4-testimonials .testimonial-box .author-info .rating li.selected .fa, .boxes.small .box-icon i, .box-container:hover .box-title, .wd-pagination .page-numbers:hover {
    color: rgba(51, 92, 255, 1);
  }
  .boxes.small.layout-3 .box-icon i,
  div.boxes.small.layout-3:hover .box-icon i {
    color: rgba(255, 255, 255, 1);
  }
  .blog-posts h2 a, .breadcrumbs > *, .breadcrumbs {
    color: rgba(58, 70, 209, 1)
  }
  .button, .button:hover, .button:focus, .products .product:hover .button,
  .woocommerce-product-search > input[type='submit'] {
    background-color: rgba(58, 70, 209, 1)
  }
  .top-bar .top-bar-right .menu li a {
    color: rgba(255, 255, 255, 1);
  }
  header.l-header .top-bar-container .site-title-bar {
    background-color: #4386F7;
  }
  header.l-header .top-bar-container .site-title-bar .menu-icon::after {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 7px 0 rgba(255, 255, 255, 1), 0 14px 0 rgba(255, 255, 255, 1);
  }
  @media only screen and (min-width: 64em) {
    header.l-header .top-bar-container.sticky.fixed, header.l-header .top-bar-container.sticky.fixed .site-navigation.top-bar {
      background-color: #4386F7;
    }
    header.l-header.creative-layout .top-bar-right .menu li a:hover {
      color: rgba(255, 255, 255, 1);
    }
    header.l-header.creative-layout .top-bar-container.sticky.fixed .top-bar-right .menu > li > a:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  .first-footer, .first-footer .block-title, .first-footer ul li a {
    color: #1B2229
  }
  #spaces-main {
    background-color: rgba(255, 255, 255, 1)
  }
  .blog-info .arrow {
    border-left-color: rgba(51, 92, 255, 1);
  }
  .ui-accordion-header-active, .ui-tabs-active, .box-icon, .woocommerce-message {
    border-top-color: rgba(51, 92, 255, 1)
  }

  .top-header .__top-header-right p {
    color: rgba(255, 255, 255, 1);
  }

  .wd-heading .title_a {
    font-weight: 600;
  }
  .wd-heading .sub_title_a {
    font-weight: 300;
    color: rgba(51, 51, 51, 1);
  }

  .wd-heading .title_b {
    font-weight: 600;
  }
  .wd-heading .sub_title_b {
    font-weight: 300;
    font-size: 24px;
    color: rgba(140, 146, 172, 1);
    line-height: 1.66;
  }

  .wd-heading .title_c {
    font-weight: 600;
    color: rgba(46, 50, 91, 1);
  }
  .wd-heading .sub_title_c {
    font-weight: 300;
    font-size: 17px;
    color: rgba(140, 146, 172, 1);
    line-height: 28px;
  }

  .wd-heading .hr_a {
    border-bottom-style: solid;
  }

  .wd-heading .hr_b {
    border-bottom-style: solid;
  }

  .wd-heading .hr_c {
    border-bottom-style: solid;
  }
}