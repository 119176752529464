.calendar-calendar .month-view .full td.multi-day div.monthview, 
.calendar-calendar .week-view .full td.multi-day div.weekview, 
.calendar-calendar .day-view .full td.multi-day div.dayview {
  background: #74A5D7;
  border-radius: 0;
}

.calendar-calendar td {
  border: 1px solid #EEEEEE;
  color: #777777;
  margin: 0;
  padding: 0;
  text-align: right;
  vertical-align: top;
  width: 14%;
}

.calendar-calendar {
  table tr.even, 
  table tr.alt, 
  table tr:nth-of-type(2n) {
    background: none;
  }
}