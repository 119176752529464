#comments {
  margin-top: 60px;
  ul.commentlist, ol.commentlist {
    list-style-type: none !important;
    margin: 0;
    li.comment {
      margin-bottom: 30px;
      list-style-type: none;
      section.comment {
        p {
          color: #666;
          font-size: 16px;
          margin: 7px 0;
        }
        .comment-reply-link {
          color: #fff;
          font-size: 14px;
          transition: .3s;
          color: $primary-color;
        }
      }
    }
    .author-meta {
      cite {
        display: block;
        margin-bottom: 10px;
        a {
          font-size: 20px;
          font-weight: 600;
        }
      }

    }
  }
}
.wd-comment {
  margin-bottom: 2em;
  padding: 50px 50px 40px;
  width: 100%;
  display: table;
  border-radius: 30px 30px 0 30px;
  box-shadow: 0 6px 24px rgba(65, 72, 82, 0.08);;
  border: 1px solid rgba(88, 136, 247, 0.1);
}
.wd-comment-author-img {
  display: table-cell;
  vertical-align: top;
  width: 120px;
  img {
    border-radius: 50%;
  }
}
.wd-comment-text {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.comments_title,
.comment-reply-title {
  margin-bottom: 1em;
}
.comment_name {
  display: inline-block;
  margin: 0 25px 0 0;
}
.comment_date {
  font-size: 11px;
}

.comment-reply-link {
  font-size: 14px;
  transition: .3s;
  color: $accent-color;
}
.comment-edit-link {
  float: right;
  font-size: 14px;
}

.comment ul.children {
  margin-left: 4rem;
}

.comment-respond {
  padding: 3em 0 4em;
}

#commentform #submit[type="submit"],
.blog-posts form .small.radius {
  border: none;
  margin-top: 20px;
  @extend .wd-btn, .btn-solid, .btn-color-1, .hover-color-2, .btn-medium, .btn-round;
}

#commentform {
  .comment-form-author input,
  .comment-form-email input,
  .comment-form-url input,
  .comment-form-comment textarea {
    border-radius: 30px 30px 0 30px;
    box-shadow: 0 1px 20px rgba(64,124,191,0.08);
    border-width: 1px;
    color: #4D4D4D;
    font-weight: 300;
    padding: 13px 26px 8px;
    line-height: 33px;
    height: auto;
    margin-top: 32px;
  }
}