.slider-home-business {
  .orbit-container {
    .orbit-slide {
      .orbit-figure {
        .orbit-caption {
          h3.companies {
            color: #fff;
          }
          p.cloud {
            color: rgba(255, 255, 255, 0.5);
          }

        }
      }
    }
  }
}

.home-page5 {
  #image-mac {
    position: absolute;
    top: 61%;
    transform: translate(-50%, -50%);
    padding-left: 50px;
    left: 76%;
  }
  .Sun {
    position: absolute;
    top: 12%;
    transform: translate(-50%, -50%);
    padding-left: 50px;
    left: 76%;
  }
}
.Numbers{
.Achievements {

  right: -336px;
  position: relative;
  @include breakpoint(small only) {
    right: 0;
  }
}
}