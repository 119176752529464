// Common abstract classes to extend or parametrics are stored here.

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

::selection {
  background: $secondary-color;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  &, & a {
    color: $header-color;
  }
}
h2         {
  letter-spacing: -.885px;
}
pre {
  border: 1px solid rgba(0, 0, 0, 0.32);
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap; 
}

.comment ul {
  list-style: outside none none;
}
.row .row {
	max-width: $row-width;
}


.collapse[class*="block-grid-"] > li {
  padding: 0;
}
.wd-vertical-aligned {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

// text align right on desktop and center on mobile
.text-right-m-c {
  text-align: center !important;
  @include breakpoint(medium up){
    text-align: right !important;
  }
}

.row-delimiter {
  width: 1px;
  position: absolute;
  bottom: 0px;
  transform: translateY(100%) translateX(900px);
}

.title_separateur {
  overflow: visible !important;
  min-height: 200px;
  &::before {
    background: #1770c4;
    content: "";
    display: block;
    height: 40px;
    left: 50%;
    position: absolute;
    bottom: -16px;
    transform: rotate(45deg);
    width: 30px;
  }
}


.paralax-bg-text {
  font-size: 260px;
  letter-spacing: 20px;
  position: absolute;
  font-weight: 900;
  color: #e9eeff;
  opacity: .4;
  left: 0;
  width: 100%;
  text-align: center;
  @include breakpoint(small only) {
    font-size: 37px;
    width: 100%;
  }
}
.wd_empty_space {
  clear: both;
}