.main ul {
	list-style: initial;
}
.list-icon li {
  list-style: none;
  i {
    padding-right: 8px;
  }
}
.wd-lists.wd-list-1 li:before {
  font-family: 'FontAwesome';
  content: '\f058';
  margin: 0 12px 0 -15px;
  color: #DC494F;
  line-height: 2.3em;
}
.wd-partenaire li {
  padding-bottom:2px;
  border:solid 1px #eee;
}
ul.social_media {
  list-style: none;
  width: 195px;
  margin: 30px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    i {
      width: 40px;
      height: 40px;
      border: 1px solid rgba(51, 51, 51, 0.3);
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
      @extend .primary-color_color;
    }
  }
}
.choose-us-list {
  margin: 0 -15px;
  overflow: hidden;
  @media #{$topbar-media-query} {
    padding-left: 25px;
  }
  li {
    float: left;
    width: 50%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    font-size: 15px;
    font-weight: 600;
    color: #444444;
    &:before {
      content: "";
      display: block;
      @extend .primary-color_bg;
      width: 8px;
      height: 8px;
      margin-right: 25px;
    }
  }
}
.vc_btn3-container {
  &.choose-us-btn {
    margin-bottom: 0;
    button.vc_btn3 {
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 2px;
      padding-left: 55px !important;
      padding-right: 50px;
      &.vc_btn3-size-md.vc_btn3-icon-left:not(.vc_btn3-o-empty) .vc_btn3-icon {
        left: 35px;
      }
      &:after {
        display: none;
      }
    }
  }
  &.find-more-btn {
    margin-bottom: 0;
    button.vc_btn3 {
      padding: 20px 75px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      &.vc_btn3-size-md.vc_btn3-icon-left:not(.vc_btn3-o-empty) .vc_btn3-icon {
        left: 35px;
      }
      &:after {
        display: none;
      }
    }
  }
}
.client-media {
  margin-bottom: 30px;
  .media-left,
  .media-body {
    display: table-cell;
    vertical-align: middle;
    @include breakpoint(small only) {
      display: block;
    }
  }
  .media-left {
    @include breakpoint(small only) {
      margin-bottom: 30px;
      text-align: center;
    }
  }
  .media-body {
    padding-left: 25px;
    @include breakpoint(small only) {
      padding-left: 0;
      text-align: center;
    }
    p {
      position: relative;
      font-size: 12px;
      font-weight: 500;
      color: #505050;
      margin-bottom: 10px;
      padding-left: 45px;
      border-left: 2px solid $primary-color;
      @include breakpoint(small only) {
        padding-left: 0;
        border-left: 0;
      }
      @extend .primary-color_border;
      &:before {
        content: "\f10d";
        font-family: "FontAwesome";
        position: absolute;
        top: -20px;
        left: 25px;
        font-size: 18px;
        @include breakpoint(small only) {
          top: -25px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    h5 {
      margin: 0;
      text-transform: uppercase;
      font-size: 11px;
      padding-left: 45px;
      @include breakpoint(small only) {
        padding-left: 0;
      }
    }
  }
}
