body div.homepage-box-after-slider {
  @include breakpoint(medium) {
    margin-top: -150px;
  }
  @include breakpoint(large up) {
    margin-top: -340px;
  }
}

.home-3-after-slider {
  box-shadow: 0 0px 90px 0px rgba(102, 118, 186, 0.2);
}

.home-1-form-section > .vc_column-inner {
  @extend .small-shadow;
}


div.screenshot {
  position: relative;
  height: 100%;
  min-height: 500px;
  @include breakpoint(small only) {
    position: relative;
    top: -126px;
    width: 390px;
  }
  .screenshot {
    &1, &2, &3 {
      display: block;
      opacity: 0;
      position: absolute;
      box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.16);
      border-radius: 25px;
    }
  }
  .screenshot1 {
    max-width: 746px;
    top: 0;
    @include breakpoint(small only) {
      top: -85px !important;

    }
  }
  .screenshot2 {
    max-width: 273px;
    top: 10%;
    left: 29.5%
  }
  .screenshot3 {
    max-width: 235px;
    top: 19%;
    left: 55%;
  }
}