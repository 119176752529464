div.wpb_row {
  margin: 0;
  @include breakpoint(small only) {
    margin: 0 -15px;
  }
}

.vc_responsive .wpb_row .full_section_container {
  .vc_span12,
  .vc_col-sm-12 {
    padding: 0;
  }
}

div.wpb_row {
  position: relative;
}

@include breakpoint(small only) {
  main.l-main.row .main {
    padding-left: 35px;
    padding-right: 35px;
  }
}


//======= single image =============
div.wpb_single_image .vc_single_image-wrapper.vc_box_shadow {
  box-shadow: 0 20px 52px 0 rgba(0,95,178,0.2);
  img {
    box-shadow: none;
    border-radius: 10px;
  }
}