$color2:#000;
.calendar-calendar th.days {
  padding: 14px 5px;
}
.table {
    background: #fff;
    -webkit-box-shadow: 0, 1px, 3px, rgba(#000, .12);
    box-shadow: 0, 1px, 3px, rgba(#000, .12);
    border-radius: 2px;
    font-size: .875em; /* 14/16 */
    width: 100%;

    th {
        background: $color2;
        color: #fff;
        padding: .5em .5em .5em 0;
        text-transform: uppercase;
        font-weight: 300;

        &:first-child {
            padding-left: 1.2em;
            -webkit-border-radius: 2px 0 0 0;
            border-radius: 2px 0 0 0;
        }
        &:last-child {
            border-radius:0 2px 0 0;
        }
    }
    tr {
        td {
            border-bottom: 1px dashed #e9e9e9;
            padding: .7em 3em .7em 0;

            &:first-child {
              padding-left: 1.2em;
            }
            &:last-child {
              padding-right: .7em;
            }
        }
        &:last-child td {
            border-bottom: none;
        }
    }

    /* Medium Screens */
    @media (max-width: 54em) {
        font-size: .8em;
        tr td {
         padding-right: 2em;
        }
    }
}


// Pricing Table
.pricing-table {
  background-color:  #000;
  padding: 20px 15px;
  border-radius: 10px;
  transition: all .4s ease-in-out;
  @extend .small-shadow--noborder;
  &:hover {
    @extend .small-shadow--with-hover;
  }
  &.featured {
    transform: translateY(-20px);
  }
  .__pricing-table-header {
    color:  inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    @media (min-width: 768px) and (max-width : 1150px) {
      padding:  0;
    }
    .__pricing-table-header-left {
      text-align: left;
      display: flex;
      flex-direction: column;
      h3 {
        margin-bottom: 0;
        font-size: 28px;
        color: inherit;
        @media (min-width: 768px) and (max-width : 1000px) {
          font-size: 13px;
        }
        @media (min-width: 1000px) and (max-width : 1150px) {
          font-size: 15px;
        }
      }
      span {
        font-size: 13px;
        font-weight: 400;
        @media (min-width: 768px) and (max-width : 1000px) {
          font-size: 10px;
        }
        @media (min-width: 1000px) and (max-width : 1150px) {
          font-size: 12px;
        }
      }
    }
    .__pricing-table-header-right {
      display: flex;
      flex-direction: column;
      .price {
        position: relative;
        padding: 0 12px;
        height: auto;
        .currency {
          position: absolute;
          top: 0;
          font-size: 18px;
          left: -3px;
          font-weight: 600;
          @media (min-width: 768px) and (max-width: 1000px) {
            font-size: 13px;
          }
          @media (min-width: 1000px) and (max-width: 1150px) {
            font-size: 15px;
          }
        }
        .price-value {
          font-size: 28px;
          font-weight: 700;
          line-height: 1;
          @media (min-width: 768px) and (max-width: 1000px) {
            font-size: 20px;
          }
          @media (min-width: 1000px) and (max-width: 1150px) {
            font-size: 30px;
          }
        }
        .value {
          position: absolute;
          top: 0;
          right: 14px;
          font-size: 12px;
          font-weight: 600;
          @include breakpoint(small only) {
            font-size: 12px;
          }
        }
      }
      span {
        font-size: 13px;
        font-weight: 400;
        @media (min-width: 768px) and (max-width: 1150px) {
          font-size: 10px;
        }
      }
    }
  }
  .__pricing-table-sub-body {
    margin: 20px 0;
    padding: 15px 0;
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff;
    ul {
      margin-bottom:  0;
      margin-left:  18px;
      @media (min-width: 768px) and (max-width : 1150px) {
        margin-left:  0;
      }
      li {
        list-style: none;
        color: inherit;
        font-weight: 500;
        padding-bottom: 10px;
        &:last-child {
          padding-bottom: 0;
        }
        @media (min-width: 1000px) and (max-width : 1150px) {
          font-size: 12px;
        }
        @media (min-width: 768px) and (max-width : 1000px) {
          font-size: 10px;
        }
      }
    }
  }
  .__pricing-table-body {
    .package-list {
      padding: 0 20px;
      margin-bottom: 0;
      margin-left: 0;
      @media (min-width: 768px) and (max-width : 1150px) {
       padding:  0;
      }
      li {
        list-style: none;
        margin-bottom: 10px;
        color: inherit;
        position: relative;
        font-weight: 400;
        @media (min-width: 768px) and (max-width : 1000px) {
          font-size: 10px;
        }
        @media (min-width: 1000px) and (max-width : 1150px) {
          font-size: 12px;
        }
        &:after {
          position: absolute;
          right: 10px;
          font-family: "Font Awesome 5 Free";
          font-weight: 700;
        }
        &.default {
          &:after {
            content: '\f00c';
            color: inherit;
          }
        }
        &.has-close {
          color: rgba(255, 255, 255, 0.8);
          &:after {
            content: '\f00d';
            color: #ffc400;
          }
        }
        &.has-addon {
          &:after {
            content: 'Add-on';
            color: #fff;
            right: 0;
            font-size: 12px;
            font-weight: 600;
            font-family: inherit;
            @media (min-width: 1000px) and (max-width : 1150px) {
              font-size: 10px;
            }
            @media (min-width: 768px) and (max-width : 1000px) {
              font-size: 8px;
            }
          }
        }
      }
    }
  }
  @include breakpoint(small only) {
    margin-bottom: 15px;
  }

  &:hover {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  &--featured{
    margin-top: -35px;
    padding-top: 45px;
    @include breakpoint(small only) {
      margin-top: 0;
    }
  }

}
