.theme-default div.nivoSlider {
  box-shadow: none;
  .nivo-caption {
    display: none !important;
  }
}


.shop-slider-section {
	.wpb_column {
		padding: 0;
	}
}
.shop-carousel.owl-carousel {
	margin: 0;
	li {
		list-style-type: none;
	}
	.shop-slider-img {

	}
	.shop-slider-text {
		padding: 0;
		h2 {
			font-size: 48px;
		}
		p {
			width: 67%;
			font-size: 13px;
		}
		background-color: #FFF;
		padding: 124px 50px 0;
		height: 469px;
		.button.wd-black.large {
			margin-top: 45px;
		}
	}
	.owl-dots {
		bottom: 33px;
		display: inline-block;
		position: absolute;
		right: 526px;
	  .owl-dot {
	  	display: inline-block;
	  	span {
	  		background-color: #efefef;
			  display: block;
			  height: 3px;
			  margin: 5px 7px;
			  transition: opacity 200ms ease 0s;
			  width: 24px;
	  	}
	  }
	  .owl-dot.active span, .owl-dot:hover span {
	  	background-color: #282828;
		}
	}
	.owl-nav > div {
		background-color: transparent;
		height: 60px;
		top: 41%;
		i {
			color: #e9e9e7;
			font-size: 60px;
		}
	}
	.owl-nav .owl-next {
		right: 46px;
	}
	.owl-nav .owl-prev {
		left: 46px;
	}
	.owl-nav > div:hover {
		background-color: transparent;
	}
}
.owl-carousel {
	&.carousel_client {
		.wd-clients-carousel-item {
			img {
				width: 140px;
			}
		}
	}
}