.sidebar-first,
.sidebar-second {
  margin-top: 10px;
  @include breakpoint(large up) {
    padding-left: 65px;
  }
  > section {
    margin-top: 80px;
    &:first-child {
      margin-top: 0;
    }
  }
}
aside.sidebar {
  section{
    .block-title{
      margin-bottom: 15px;
    }
    > ul > li {
      padding : 10px 0;
      border-bottom: 1px dashed #eee;
    }
  }
  ul {
    margin: 0;
  }
  li {
    list-style: none;
    width: 100%;
  }
  a {
    color: inherit;
    font-weight: 400;
    &:hover {
      color: $accent-color;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    a {
      color: inherit;
      font-weight: inherit;
    }
  }
  #menu-services-menu {
    li {
      padding: 10px 0;
    }
  }



  #prev  {
    a {
      color:#fff;
    }
  }
  #next  {
    a {
      color:#fff;
    }
  }
}

.sidebar section ul.children li {
  padding : 10px 0;
}


.sidebar-second.sidebar.sidebar-left {
  margin-top: 0;
  width: 100%;
  padding: 0px 17px 0 15px;
  @include breakpoint(large) {
    width: 25%;
  }

  h2 {
    font-weight: 600;
  }
  ul {
    &.menu {
      margin: 0;
    }
    li::before {
      content: "";
      margin: 0;
    }
    li {
      background-color: $secondary-color;
      margin: 0;
      transition: all .3s ease 0s;
      border-radius: 30px 30px 0 30px;
      a {
        font-size: 15px;
        font-weight: 700;
        color: #fff;
      }
      &:first-child {
        border-top: 0;
      }
    }
  }
  .widget-documents {
    .widget-subtitle {
      color: #666;
      font-size: 15px;
      font-weight: 600;
    }
    ul {
      margin: 20px 0 0;
      li {
        box-shadow: 0 0 2px 0 rgba(187, 187, 187, 0.53);
        margin: 15px 0;
        padding: 8px 20px 8px 25px;
        color: #787b7f;
        border: none;
        a {
          font-size: 13px;
          font-weight: 600;
          padding: 8px 10px;
        }
        i,svg {
          margin-top: 0;
          font-size: 15px;
          margin-right: 10px;
          color: #fff;
        }
        &:hover {
          background-color: #FFF;
          box-shadow: 0 0 3px 3px rgba(187, 187, 187, 0.53);
          a {
            color: #555;
          }
          i,svg {
            color: #000;
          }
        }
      }
    }
  }
  .widget_nav_menu {
    ul {
      margin-left: 15px;
      .menu-item {
        list-style: square;
        padding-left: 0;
        background: none;
        &:before {
          content: none;
        }
        a {
          padding-left: 5px;
          color: #666b9a;
        }
      }
    }
  }
}

