.slider-home-business.slide-home1 {
  .orbit-controls {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9;
    width: 100%;
    padding: 0 50px;
    opacity: 0;
    transition: all 400ms ease-in-out;
    @include breakpoint(small only) {
      display: none;
    }
  }
  .orbit-wrapper {
    .orbit-container {
      .orbit-slide {
        .orbit-figure {
          .orbit-image {
            @include breakpoint(small only) {
              display: none ;
            }}
          .orbit-image-mobile
          {
            display: none;
            @include breakpoint(small only) {
              display: inline-block;
              height: 500px;
            }
          }
          .orbit-caption {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding-left: 50px;
            left: 50%;

            .orbit-image {
              background-size: cover;
              background-position: center bottom;
              width: 100%;
              height: 100%;
              opacity: 1;
              visibility: inherit;
              z-index: 20;
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: 0;
              width: 100%;
              height: 100%;
              visibility: inherit;
              opacity: 1;
              transform: matrix(1, 0, 0, 1, 0, 0);
            }
            h3 {
              color: #ffffff;
              font-size: 56px;
              line-height: 60px;
              font-weight: 600;
              font-style: normal;
              font-family: Martel Sans;
              text-decoration: none;
              background-color: transparent;
              border-color: transparent;
              border-style: none;
              border-width: 0px 0px 1px 0px;
              border-radius: 0px 0px 0px 0px;
              position: relative;
              top: 30%;
              @include breakpoint(small only) {
                max-width: 398px;
                text-align: center;
                line-height: 42px;
                margin: 0px;
                font-weight: 900;
                font-size: 26px;
              }
            }
            p {
              position: relative;
              top: 30%;
              z-index: 6;
              min-width: 553px;
              max-width: 553px;
              white-space: normal;
              font-size: 24px;
              line-height: 36px;
              font-weight: 300;
              color: rgba(255, 255, 255, 0.5);
              letter-spacing: 0px;
              font-family: Martel Sans;
              visibility: inherit;
              transition: none 0s ease 0s;
              text-align: inherit;
              border-width: 0px;
              margin: 0px;
              padding: 0px;
              min-height: 124px;
              max-height: 124px;
              opacity: 1;
              transform-origin: 50% 50% 0px;
              transform: translate3d(0px, 0px, 0px);
              @include breakpoint(small only) {
                min-width: 369px;
                max-width: 369px;
                white-space: normal;
                font-size: 13px;
                line-height: 21px;
                text-align: center;
                min-height: 82px;
              }
            }
            .image1 {
              width: 612px;
              @include breakpoint(small only) {
                position: relative;
                top: 58%;
                transform: translate(-50%, -50%);
                left: 50%;
              }
            }

            button {
              z-index: 22;
              min-width: 163px;
              max-width: 163px;
              white-space: normal;
              font-size: 12px;
              line-height: 24px;
              font-weight: 700;
              color: rgb(255, 255, 255);
              letter-spacing: 0px;
              font-family: Martel Sans;
              text-transform: uppercase;
              background-color: rgb(255, 51, 127);
              border-radius: 30px 30px 0px;
              outline: currentcolor none medium;
              box-shadow: none;
              box-sizing: border-box;
              visibility: inherit;
              transition: none 0s ease 0s;
              text-align: inherit;
              border-width: 0px;
              margin: 0px;
              padding: 10px 30px;
              min-height: 43px;
              max-height: 43px;
              opacity: 1;
              transform: translate3d(0px, 0px, 0px);
              transform-origin: 50% 50% 0px;
            }
            .slide-home2-left {
              h3, p, button {
                position: relative;
                top: -13%;
              }
            }
          }
        }
      }
    }
  }
}
