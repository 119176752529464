.count1, .count2, .count3, .count4{
  padding: 50px 0;
}
.count1{
  background-color: #b3006b;
}
.count2{
  background-color: #fa2a57;
}
.count3{
  background-color: #ff7701;
}
.count4{
  background-color: #ffb320;

}
.map-offices{
  //background-image: url("../images/bg9.jpg");
  background-position: center bottom;
  background-repeat: no-repeat ;

}

.top-bar .name h1 a{
  width: auto;
}
header .button.home-cta {
  border: medium none;
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-weight: 800;
  height: 46px;
  margin: 30px 40px 30px 115px;
  padding: 14px 30px;
}
.wpcf7-form-control-wrap textarea{
  margin: 0 0 1rem 0;
}
.wpcf7-form-control.wpcf7-submit {
  padding: 12px 75px;
}
h2.contact-us::after, .contact-us-info h2::after, .who-we-are h2::after{
  content: " ";
  display: block;
  @extend .primary-color_bg;
  height: 3px;
  width: 20px;
  margin: 8px 0 10px 0;
}
.contact-us-info ul.information-list li {
  list-style: none;
  overflow: hidden;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.contact-us-info ul.information-list li i {
  float: left;
  color: #333333;
  font-size: 13px;
  display: inline-block;
  line-height: 24px;
}
.contact-us-info p {
  margin-bottom: 30px;
}
.contact-us-info ul.information-list li span, .contact-us-info ul.information-list li a {
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
  margin-left: 25px;
  color: #333333;
}
.contact-us-info ul.information-list  {
  margin-left: 0;
}
.contact-us-info{
  margin-left: 15px;
}
.vc_general.vc_cta3.about-call{
  padding: 100px 28px;
}
.who-we-are h4 {
  @extend .primary-color_color;
}

.about-presentation-p {
  padding-right: 25px;
  padding-top: 10px;
}

.vc_row {
  .--row-has-overflow {
    overflow: hidden;
  }
  .custom-heading {
      .contact-2-heading {
        h2 {
          position: relative;
          display: inline-block;
          margin-bottom: 30px !important;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background: $primary-color;
          }
        }
        p {
          font-size: 16px;
          color: #D1D1D1;
          font-weight: 600;
          br {
            line-height: 32px;
          }
        }
    }
  }
  .overflow-form {
    #wpcf7-f886-p887-o1 {
      overflow: hidden;
    }
    >.vc_column-inner {
      border-top-right-radius: inherit !important;
      border-bottom-right-radius: inherit !important;
      box-shadow: 0 10px 21px 0 rgba(0, 0, 0, .06);
    }


    }
  .left-image {
    position: absolute;
    left: -370px;
    top: -245px;
    width: 722px;
    height: 815px;
    z-index: -1;
    @include breakpoint(small only) {
      display: none;
    }
  }
  .right2-image {
    position: absolute;
    right: -340px;
    width: 240px;
    height: 240px;
    top: -100px;
    @include breakpoint(small only) {
      display: none;
    }
  }
  .right1-image {
    position: absolute;
    right: -90px;
    width: 240px;
    height: 240px;
    bottom: -173px;
    z-index: -1;
    @include breakpoint(small only) {
      display: none;
    }
  }
  &.custom-map {
    top: -200px;
    margin-bottom: -462px;
    z-index: -1;
    @include breakpoint(small only) {
      top: 0;
      margin-bottom: 0px;
    }
    .wpb_column {
      .vc_column-inner {
        padding: 0;
        @include breakpoint(small only) {
          padding: 15px;
        }
      }
    }
  }

  .contact-informations {
    >.vc_column-inner {
      border-top-left-radius: inherit !important;
      border-bottom-left-radius: inherit !important;
      box-shadow: 0 10px 21px 0 rgba(0, 0, 0, .06);
      @include breakpoint(small only) {
        padding:  50px 15px !important;
      }
    }
    .boxes.layout-1 {
      .box-icon {
        .fa {
          color: #001774;
          font-size: 25px;
          &.fa-mobile {
            font-size: 35px;
          }
          &.fa-map-marker {
            font-size: 30px;
          }
        }
      }

      .box-title-1 {
        color: #333333;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
      }

      .box-body {
        color: #FFFFFF;
        font-weight: 600;
        font-size: 13px;
        br {
          line-height: 24px;
        }
      }
    }
  }
  }