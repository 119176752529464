.wd-post {
  box-shadow: 0 5px 20px rgba(64, 124, 191, 0.05);
  border: 1px solid rgba(88, 136, 247, 0.2);
  border-top: 3px solid $accent-color ;
  overflow: hidden;
  margin-bottom: 100px;
  background: #FFF;
  transition: all .3s ease;
  &:hover {
    box-shadow: 0 20px 60px rgba(4, 75, 194, 0.1);
    border: 1px solid rgba(88, 136, 247, 0.1);
    transform: translateY(-7px);
  }

  &__thumbnail {
    &--gallery {
      margin: 0;
    }
    &--single {
      margin-bottom: 35px;
    }
  }

  &__content {
    padding: 35px 50px 40px;
    position: relative;
    &--single {
      padding: 0;
      color: opacify($headings-color, .98);
    }
  }
  &__body {
    margin-bottom: 1em;
    &--single p, &--single {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.66;
    }
  }

  &__title {
    margin: 20px 0 4px;
  }

  &__meta {
    margin: 10px 0 20px;
    color: rgba($text-color, .8);
    &--single {
      margin-bottom: 3em;
    }
    li {
      float: left;
      margin-right: 10px;
      padding-right: 5px;
      line-height: 20px;
      list-style: none;
      font-size: 14px;
      &:after {
        content: '-';
        position: relative;
        left: 7px;
        color: #666;
      }
      &:last-child:after {
        display: none;
      }
    }
  }

  &__read-more {
    margin-top: 15px;
    a {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all .3s;
      &:hover {
        color: $headings-color;
        margin-left: 15px;
        img {
          margin-left: 10px;
        }
      }
      img {
        width: 10px;
        height: 10px;
        margin-left: 5px;
        margin-bottom: 2px;
        transition: all .3s ease;
      }
    }
  }
  //----- sticky post -----------
  &.sticky {
    border-top: 5px solid $primary-color;
    border-bottom: 5px solid $primary-color;
    padding: 20px;
  }
  //------- link ---------------
  &--link {
    background: $primary-color;
    padding: 50px 50px 40px;
    text-align: center;
    a, i {
      color: #fff;
    }

    svg.fa-link {
      color: #fff;
      margin-bottom: 10px;
      font-size: 25px;
    }
  }
  //------- quote ---------------
  &--quote {
    background: $primary-color;
    padding: 50px 50px 40px;
    text-align: center;
    i {
      color: #fff;
    }
    blockquote {
      border: none;
      p {
        color: #fff;
      }
    }
    svg.fa-quote-right {
      color: #fff;
      font-size: 28px;
    }
    .author {
      color: #fff;
    }
  }

  //---------- Shortcode -----------
  &--multicolumn {
    box-shadow: 0 10px 50px rgba(4, 75, 194, 0.1);
    border: 1px solid rgba(88, 136, 247, 0.1);
    border-radius: 30px 30px 0 30px;
  }
  &--multicolumn & {
    &__meta {
      margin: 8px 0 13px;
    }
    &__content {
      padding: 10px 30px 30px;
    }
    &__author,
    &__comments {
      display: none;
    }
    &__read-more {
      a img {
        filter: opacity(.5);
      }
    }
  }

  //------------ pagination -------------
  &__pagination {
    text-align: center;
    padding: 30px 0;
    .page-numbers {
      padding:9px 18px 2px;
      border-bottom: 3px solid $primary-color;
      color: $primary-color;
      margin-right: 13px;
      display: inline-block;
      background: #f5f5f5;
      &.current, &:hover {
        background: rgba($accent-color, .05);
        color: $accent-color;
        border-color: $accent-color;
        a {
          color: #fff;
        }
      }
      &.next, &.prev, &.dots {
        border: none;
        background: none;
        padding-left: 0px;
        padding-right: 0px;
        transition: all .3s ease, color .3s ease .3s;
        &:hover {
          border: none;
          background: none;
          color: $accent-color;
          background: rgba($accent-color, .05);
        }
      }
      &.next:hover {
        transform: translateX(10px);
      }
      &.prev:hover {
        transform: translateX(-10px);
      }
    }
  }
}



//------------ single -------------
.single .wd-post__body {
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.4;
    letter-spacing: 0;
    margin-top: 1em;
  }
  h1 {
    font-size: 55px;
  }
  h2 {
    font-size: 44px;
  }
  h3 {
    font-size: 30px;
  }
}












