.l-header.corporate-layout {
  ul {
    list-style: none;
  }
  .top-bar-container {
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.2);
    transition: all .35s ease-in-out;
    padding: 0 40px;
    z-index: 23;
    @include breakpoint(small only) {
      padding: 0;
    }
    .site-title-bar.title-bar {
      padding: 10px 15px;
      border-bottom: 1px solid #EEE;
      .menu-icon {
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        &:focus {
          outline: none;
        }
        &:after {
          background: #000000;
          box-shadow: 0 7px 0 #000000, 0 14px 0 #000000;
        }
      }
      .site-mobile-title {
        a {
          padding-top: 0;
          padding-bottom: 0;
          img {
            max-height: 40px;
          }
        }
      }
    }
    .mobile-menu {
      padding: 12px 0;
      &.menu .menu {
        li {
          a {
            font-size: 15px;
            padding: 15px;
          }
          .submenu-toggle {
            background-color: transparent;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .site-navigation {
      &.top-bar {
        padding: 12px 0;
        @include breakpoint(small only) {
          padding: 0;
        }
        .top-bar-left {
          .top-bar-title {
            margin: 0;
            .logo-wrapper {
              .menu-text {
                margin-right: 90px;
                a {
                  display: block;
                }
              }
            }
          }
        }
        .top-bar-right {
          justify-content: flex-end;
          .menu {
            justify-content: left;
            &.desktop-menu {
              li {
                a {
                  font-size: 15px;
                  line-height: 36px;
                  color: #484848;
                  padding: 15px 25px;
                }
                &.is-dropdown-submenu-parent {
                  > a {
                    padding-right: 35px;
                    &:after {
                      content: "\f107";
                      font-family: "FontAwesome";
                      border: 0;
                      top: 53%;
                      transform: translateY(-50%);
                      width: auto;
                      height: auto;
                      margin: 0;
                      right: 20px;
                    }
                  }
                  .is-dropdown-submenu {
                    border-radius: 0;
                    li {
                      a {
                        padding: 10px 20px;
                        line-height: 25px;
                        border-color: transparent;
                      }
                      &.is-dropdown-submenu-parent {
                        > a {
                          &:after {
                            content: "\f105";
                            right: 15px;
                            top: 50%;
                          }
                        }
                      }
                    }
                  }
                }
                &.active {
                  > a {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}