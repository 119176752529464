.wd-heading {
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  .first-title {
    font-size: 22px;
  }
  h2 {
    span {
      display: inline-block;
      br {
        line-height: 0;
      }
    }
  }
}